import React, { useState, useEffect } from "react";

import { Container, SearchBar, Text } from "@atoms";
import { PersonCard, Pagination } from "@molecules";
import algoliasearch from "algoliasearch";

// todo: refactor

const client = algoliasearch(
  process.env.GATSBY_ALGOLIA_DIRECTORY_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
);
const peopleIndex = client.initIndex("People");

const Directory = ({
  people,
  departmentFilter: departmentFilterState,
  lNameFilter: lNameFilterState,
  id,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [peopleToDisplay, setPeopleToDisplay] = useState([...people]);
  const [page, setPage] = useState(1);
  const [newQuery, setNewQuery] = useState("");
  const [query, setQuery] = useState("");
  const [results, setResults] = useState({ peopleToDisplay });
  // eslint-disable-next-line no-unused-vars
  const [lNameFilter, setLNameFilter] = lNameFilterState;
  // eslint-disable-next-line no-unused-vars
  const [departmentFilter, setDepartmentFilter] = departmentFilterState;

  const pageFilter = p => {
    // filter out based on filter settings on the side
    let filtered = false;
    if (lNameFilter) {
      filtered =
        !p?.lastName?.startsWith(lNameFilter) &&
        !p?.lastName?.startsWith(lNameFilter?.toUpperCase());
    }
    if (departmentFilter && !filtered) {
      filtered = true;
      p.departments.forEach(d => {
        if (departmentFilter === d.department) {
          filtered = false;
        }
      });
    }
    return !filtered;
  };

  useEffect(() => {
    if (!query && !lNameFilter && !departmentFilter) {
      // sort people randomly
      // eslint-disable-next-line no-plusplus
      for (let a = 0; a < peopleToDisplay.length; a++) {
        const x = peopleToDisplay[a];
        const y = Math.floor(Math.random() * (a + 1));
        peopleToDisplay[a] = peopleToDisplay[y];
        peopleToDisplay[y] = x;
      }
      setResults({ peopleToDisplay });
    } else {
      if (query && query.length > 2 && query !== "") {
        // search people
        peopleIndex
          .search(query)
          .then(({ hits }) => {
            setResults({ ...results, peopleToDisplay: hits });
            // peopleToDisplay.filter(x => hits.includes(x));
          })
          .catch(err => {
            // eslint-disable-next-line no-console
            console.log(err);
          });
      }
      // filter and search
      if (lNameFilter || departmentFilter) {
        peopleToDisplay.filter(p => pageFilter(p));
      }
      // sort alphabetically
      peopleToDisplay.sort(
        (a, b) =>
          a?.lastName
            ?.toLowerCase()
            ?.localeCompare(b?.lastName?.toLowerCase()) ||
          a?.firstName
            ?.toLowerCase()
            ?.localeCompare(b?.firstName?.toLowerCase())
      );
      setResults({ peopleToDisplay });
    }
  }, [query, lNameFilter, departmentFilter]);

  //     useEffect(() => {
  //       if (!lNameFilter && !departmentFilter) {
  // console.log('set people');
  //         setResults({ people });
  //       }
  //     }, [lNameFilter, departmentFilter]);

  // perform a new search when query changes
  //     useEffect(() => {
  //       if (query && query.length > 2 && query !== "") {
  // console.log('query change');
  //         // search people
  //         peopleIndex
  //           .search(query)
  //           .then(({ hits }) => {
  // console.log(hits);
  //             setResults({ ...results, people: hits });
  //           })
  //           .catch(err => {
  //             // eslint-disable-next-line no-console
  //             console.log(err);
  //           });
  //       }
  //     }, [query]);

  return (
    <div id={id} className="py-12 sm:py-16">
      <Container variant="xs" padding>
        {/* search bar */}
        <div className="max-w-xl">
          <SearchBar
            id="directory_search"
            inverse={false}
            canSearch={newQuery !== query}
            onQueryChange={setNewQuery}
            searchFor={setQuery}
            placeholder="Search by Last Name"
            focusOnLoad
          />
        </div>

        {/* results */}
        <div className="mt-10">
          {results.peopleToDisplay &&
            results.peopleToDisplay.length > 0 &&
            results.peopleToDisplay
              .filter(p => pageFilter(p))
              // set page
              .filter((p, i) => i >= (page - 1) * 5 && i < page * 5)
              .map((person, i) => {
                const cardData = person;
                // parse the data
                cardData.titles =
                  typeof cardData.titles === "string"
                    ? JSON.parse(cardData.titles)
                    : cardData.titles;
                cardData.departments =
                  typeof cardData.departments === "string"
                    ? JSON.parse(cardData.departments)
                    : cardData.departments;
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={i} className="mb-6">
                    <PersonCard
                      key={`person-result-${person.objectID}`}
                      {...person}
                      directoryLink={
                        person.path && person.path.length > 1 ? person.path : ""
                      }
                      count={i}
                    />
                  </div>
                );
              })}
          {results.peopleToDisplay &&
            results.peopleToDisplay.filter(p => pageFilter(p)).length < 1 && (
              <Text variant="mono" className="py-12 text-center text-blue-dark">
                Sorry, your search returned no people.
              </Text>
            )}
        </div>
        {Math.ceil(
          results.peopleToDisplay.filter(p => pageFilter(p)).length / 5
        ) > 1 && (
          <Pagination
            pages={Math.ceil(
              results.peopleToDisplay.filter(p => pageFilter(p)).length / 5
            )}
            currentPage={page}
            setPage={setPage}
          />
        )}
      </Container>
    </div>
  );
};

Directory.defaultProps = {
  people: [],
  peopleToDisplay: [],
  departmentFilter: null,
  lNameFilter: null,
};

export default Directory;
