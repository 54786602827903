import React, { useState, useRef, useEffect } from "react";

import useWindowSize from "@hooks/useWindowSize";
import { Container, Text, RichText } from "@atoms";
import { AppLink } from "@defaults";
import SideBar from "./SideBar";

const IntroWCopyAndLinks = ({
  sideBarImage,
  sideBarSections,
  lead,
  copy,
  heading,
  links,
  addlCopy,
  family,
  linkObj: LinkObj,
  id,
}) => {
  const [minHeight, setMinHeight] = useState("none");
  const sidebarContainer = useRef();
  const { innerWidth: windowSize } = useWindowSize();
  useEffect(() => {
    if (sidebarContainer.current) {
      setMinHeight(sidebarContainer.current.clientHeight - 48);
    }
  }, [windowSize]);
  return (
    <Container>
      <div
        id={id}
        style={{ minHeight }}
        className="relative pt-6 sm:pt-12 lg:py-12"
      >
        <Container padding interior>
          <div
            className={`flex flex-wrap ${
              sideBarSections || sideBarImage ? "lg:pl-32 xl:pl-32" : ""
            } pt-2`}
          >
            {heading && (
              <Text
                variant="h4"
                className="mb-12 w-full font-bold text-blue-dark lg:mt-12"
              >
                {heading}
              </Text>
            )}
            <div className="max-w-2xl pr-6 pt-4 md:w-3/5 lg:w-2/3">
              {lead && (
                <div>
                  <Text
                    variant="body--large"
                    className="rich-text mb-6 leading-normal"
                  >
                    <RichText content={lead} />
                  </Text>
                </div>
              )}
              {copy && (
                <div>
                  <Text variant="body" className="rich-text leading-normal">
                    <RichText content={copy} />
                  </Text>
                </div>
              )}
            </div>

            {/* addlCopy */}
            {(addlCopy || links) && (
              <div className="order-1 w-full sm:order-2 sm:w-1/3 lg:w-1/5">
                <div className="pt-4 sm:-mr-12 md:-mr-16 lg:-mr-24">
                  {addlCopy && (
                    <Text variant="body" className="leading-normal">
                      <RichText content={addlCopy} />
                    </Text>
                  )}
                  {links &&
                    links.length > 0 &&
                    links.map((item, i) => {
                      const { link } = item;
                      if (link) {
                        return (
                          <LinkObj
                            // eslint-disable-next-line react/no-array-index-key
                            key={i}
                            to={link.link}
                            className="my-3 block w-full text-blue transition hover:text-orange"
                          >
                            <Text
                              variant="mono--small-label"
                              className="underline-1"
                            >
                              {link.label}
                            </Text>
                          </LinkObj>
                        );
                      }
                      return null;
                    })}
                </div>
              </div>
            )}
          </div>
        </Container>
        {/* sidebar */}
        {(sideBarSections || sideBarImage) && (
          <div className="-mx-3 sm:-mx-6">
            <div
              ref={sidebarContainer}
              className="z-20 order-2 my-6 w-full lg:absolute lg:left-0 lg:top-0 lg:order-1 lg:-mb-20 lg:-ml-12 lg:-mt-6 lg:w-auto"
            >
              <div className="lg:-ml-3">
                <SideBar
                  image={sideBarImage}
                  sections={sideBarSections}
                  family={family}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </Container>
  );
};

IntroWCopyAndLinks.defaultProps = {
  sideBarImage: null,
  heading: null,
  sideBarSections: null,
  lead: null,
  copy: null,
  addlCopy: null,
  links: null,
  linkObj: AppLink,
};

export default IntroWCopyAndLinks;
