import React, { useEffect, useRef, useState } from "react";
import { Text, Button } from "@atoms";
import moment from "moment";

const ModalEvent = ({
  id,
  title,
  building,
  roomNumber,
  roomName,
  category,
  start,
  end,
  url,
  meta,
  noLabel,
}) => {
  const [copiedText, setCopiedText] = useState(false);
  const urlInput = useRef();

  const copyUrl = () => {
    if (urlInput.current) {
      urlInput.current.select();
      document.execCommand("copy");
      setCopiedText(true);
    }
  };

  useEffect(() => {
    setTimeout(() => setCopiedText(false), 2500);
  }, [copiedText]);

  const resolveDates = (startDate, endDate) => {
    const sd = startDate ? moment(startDate) : false;
    const ed = endDate ? moment(endDate) : false;
    if (sd && ed) {
      if (sd.format("MMDDYYYY") === ed.format("MMDDYYYY")) {
        return `${sd.format("MMMM D @ h:mma")} - ${ed.format("h:mma")}`;
      }
      return `${sd.format("MMMM D @ h:mma")} - ${ed.format("MMMM D @ h:mma")}`;
    }
    if (sd) {
      return `${sd.format("MMMM D @ h:mma")}`;
    }
    return false;
  };

  const dateTimes = resolveDates(start, end);

  return (
    <div className="max-w-lg bg-white p-8 px-14">
      {!noLabel && category && !!category.length && (
        <Text variant="mono--extra-small" className="mb-3 text-blue-light">
          {category}
        </Text>
      )}
      <Text variant="body" className="mt-3 font-bold text-blue">
        {title}
      </Text>
      {dateTimes && (
        <div className="my-1 font-mono text-xs uppercase text-black">
          {dateTimes}
        </div>
      )}
      <span className="mt-1 text-xs opacity-75">
        {building} {roomNumber}
      </span>
      {roomName && roomName !== roomNumber && (
        <span className="mt-1 text-xs opacity-75">{roomName}</span>
      )}
      {meta && (
        <>
          <span className="mt-2 block text-xs opacity-75">
            {meta.building} {meta.roomNumber}
          </span>
          {meta.roomName && meta.roomName !== meta.roomNumber && (
            <span className="mt-1 block text-xs opacity-75">
              {meta.roomName}
            </span>
          )}
          {meta.category && (
            <span className="mt-1 block text-xs opacity-75">
              {meta.category || category}
            </span>
          )}
        </>
      )}
      {category && noLabel && !!category.length && (
        <span className="mt-1 block text-xs opacity-75">{category}</span>
      )}
      <div className="mt-2 flex items-center">
        <label
          htmlFor="eventUrl"
          className="absolute left-[-9999px] top-[-9999px]"
        >
          <Text className="mr-1 mt-1 text-xs opacity-75">Share url: </Text>
          <input
            ref={urlInput}
            className="mt-1 bg-black p-1 text-xs text-white"
            value={`${url?.origin}${url?.pathname}?event=${id}`}
            type="text"
            readOnly
          />
        </label>

        <Button
          type="button"
          size="sm"
          className="mt-3"
          onClick={() => copyUrl()}
        >
          {copiedText ? "Copied" : "Copy Event URL"}
        </Button>
      </div>
    </div>
  );
};

export default ModalEvent;
