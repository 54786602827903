import React from "react";

import { RichText, Text, Container, Icon, Button } from "@atoms";

const HeadingWCopy = ({
  heading,
  subHeading,
  size,
  button,
  background,
  watermark,
  copy,
  layout,
  id,
}) => {
  const sizes = {
    large: "body--large",
    normal: "body",
    small: "body--small",
  };
  const variant = sizes[size] || "body";
  return (
    <div id={id} className={`relative bg-${background} z-20 py-12 sm:py-16`}>
      {watermark === "show" && (
        <Icon
          name="wordmark"
          className="absolute bottom-0 left-0 right-0 z-0 -m-2 -mb-12 text-white opacity-25"
        />
      )}
      <Container variant={layout === "Two Columns" ? "md" : "xs"} padding>
        {(heading || subHeading) && (
          <div className="mb-6">
            {heading && (
              <Text variant="mono--large-label" className="text-blue">
                {heading}
              </Text>
            )}
            {subHeading && (
              <Text
                variant="h5"
                className="font-bold leading-tight text-blue-dark"
              >
                {subHeading}
              </Text>
            )}
          </div>
        )}
        <Text
          variant={variant}
          className={` ${layout === "Two Columns" ? "two-columns--copy" : ""} rich-text`}
        >
          <RichText content={copy} />
        </Text>
        {button && (
          <div className="my-4">
            <Button to={button.link}>{button.label}</Button>
          </div>
        )}
      </Container>
    </div>
  );
};

HeadingWCopy.defaultProps = {
  size: "normal",
  heading: null,
  subHeading: null,
  button: { link: "", label: "" },
  watermark: "hide",
  background: "peach",
  copy: null,
  layout: "One Column",
};

export default HeadingWCopy;
