import React from "react";

import SideBar from "./SideBar";

const RelativeNav = ({ family, float: _float, heading, id }) => {
  const float = _float === "Float Left" ? true : _float !== "Regular" || _float;
  const sideBarSections = [
    {
      type: "relativeNav",
      heading,
      items: family,
    },
  ];
  return (
    <div id={id}>
      <div
        className={
          float
            ? "z-20 my-6 lg:absolute lg:left-0 lg:top-0 lg:-mb-20 lg:-ml-12 lg:-mt-6 lg:w-auto"
            : ""
        }
      >
        <div className={float ? "lg:-ml-3" : ""}>
          <SideBar sections={sideBarSections} family={family} />
        </div>
      </div>
    </div>
  );
};

RelativeNav.defaultProps = {
  family: null,
  float: true,
  heading: null,
};

export default RelativeNav;
