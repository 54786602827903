import React from "react";

import { EventListItemWide } from "@molecules";
import { Container, Text } from "@atoms";

const ImportantDates = ({ heading, subHeading, dates, id }) => {
  return (
    <div id={id} className="bg-blue-superlight py-12 sm:py-16">
      <Container padding>
        {(heading || subHeading) && (
          <div className="mb-12 text-center">
            {heading && (
              <Text variant="mono--large-label" className="mb-1 text-blue">
                {heading}
              </Text>
            )}
            {subHeading && (
              <Text variant="h4" className="font-bold text-blue-dark">
                {subHeading}
              </Text>
            )}
          </div>
        )}
        <ul>
          {dates &&
            dates.length > 0 &&
            dates.map((date, i) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <li key={i}>
                  <EventListItemWide {...date} />
                </li>
              );
            })}
        </ul>
      </Container>
    </div>
  );
};

ImportantDates.defaultProps = { heading: null, subHeading: null, dates: null };

export default ImportantDates;
