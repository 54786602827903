import React from "react";

import { Text, Icon, RichText } from "@atoms";
import { AppLink } from "@defaults";

const SideBarIcon = ({ icon, link, desc, linkObj: LinkObj }) => {
  if (link) {
    if (link.link) {
      return (
        <LinkObj
          to={link.link}
          className="group text-blue transition hover:text-orange"
        >
          <div className="flex flex-row items-center">
            <div className="mr-3 flex-shrink-0 text-blue">
              <Icon name={icon || "page"} className="h-4 w-4" />
            </div>
            <Text variant="mono--extra-small-label">{link.label}</Text>
          </div>
          {desc && (
            <div className="flex flex-row pl-1">
              <div className="ml-6 flex-grow">
                <Text
                  variant="xs"
                  className="font-sans font-bold leading-tight text-blue-dark"
                >
                  <RichText content={desc} />
                </Text>
              </div>
            </div>
          )}
        </LinkObj>
      );
    }
    return (
      <div className="text-blue-dark transition">
        <div className="flex flex-row items-center">
          <div className="mr-3 flex-shrink-0 text-blue">
            <Icon name={icon || "page"} className="h-4 w-4" />
          </div>
          <Text variant="mono--extra-small-label" className="text-blue">
            {link.label}
          </Text>
        </div>
        {desc && (
          <div className="flex flex-row pl-1">
            <div className="ml-6 flex-grow">
              <Text
                variant="xs"
                className="font-sans font-bold leading-tight text-blue-dark"
              >
                <RichText content={desc} unstyled />
              </Text>
            </div>
          </div>
        )}
      </div>
    );
  }
  return null;
};

SideBarIcon.defaultProps = {
  linkObj: AppLink,
  icon: null,
  desc: null,
  link: null,
};

export default SideBarIcon;
