import React from "react";

import { Container, Text, Button, SearchBar, Icon } from "@atoms";
import {
  LinkListMedium,
  LinkListSmall,
  LocationsList,
  SocialLinks,
} from "@molecules";
import { AppLink } from "@defaults";

const Footer = ({
  primaryNav,
  secondaryNav,
  locations,
  socialLinks,
  button,
  linkObj: LinkObj,
}) => {
  return (
    <div className="bg-blue-dark p-6 sm:py-6 md:py-8 lg:py-12">
      <Container>
        {/* top row */}
        <div className="-mx-3 flex flex-wrap sm:-mx-6">
          {/* nav */}
          <div className="mb-12 w-full px-3 sm:px-6 md:w-3/5 xl:w-2/3">
            <Text variant="mono--small-label" className="mb-6 text-blue-light">
              Find Your Path
            </Text>
            <div className="flex flex-wrap items-start">
              <div className="w-full sm:w-2/5">
                <LinkListMedium links={primaryNav} linkObj={LinkObj} />
              </div>
              <div className="two-columns w-full sm:w-3/5">
                <LinkListSmall links={secondaryNav} linkObj={LinkObj} />
              </div>
            </div>
          </div>
          {/* locations + Social */}
          <div className="mb-12 w-full px-3 sm:px-6 md:w-2/5 xl:w-1/3">
            <Text
              variant="mono--small-label"
              className="mb-6 flex-shrink-0 text-blue-light"
            >
              Locations
            </Text>
            {/* locations */}
            <LocationsList locations={locations} />
            {/* social */}
            <SocialLinks links={socialLinks} linkObj={LinkObj} />
          </div>
          {/* bottom row */}
          <div className="mb-12 w-full px-3 sm:px-6">
            <div className="flex flex-wrap items-end sm:-mx-6">
              {/* button + search */}
              <div className="mb-12 flex w-full flex-wrap sm:order-2 sm:w-4/7 sm:px-6 md:order-1 md:w-3/5 md:flex-nowrap lg:w-1/2">
                <div className="mb-6 w-full flex-shrink-0 pr-6 sm:mb-0 md:w-auto lg:pr-24">
                  <Button to={button.link} color="orange">
                    {button.label}
                  </Button>
                </div>
                <div className="max-w-52 w-full">
                  <SearchBar id="footer_search" />
                </div>
              </div>
              {/* logo */}
              <div className="mb-12 w-full sm:order-1 sm:w-3/7 sm:px-6 md:order-2 md:ml-auto md:w-1/3 lg:w-1/2">
                <Icon
                  name="logoInverse"
                  className="w-48 sm:w-full md:ml-auto md:w-56"
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

Footer.defaultProps = {
  primaryNav: null,
  secondaryNav: null,
  locations: [
    {
      name: "Aptos Campus",
      address1: "6500 Soquel Drive",
      cityStateZip: "Aptos, CA 95003",
      phone: "(831) 479-6100",
      googleMapsURL: "https://goo.gl/maps/StodHW8An3S2",
    },
    {
      name: "Watsonville Center",
      address1: "318 Union Street",
      cityStateZip: "Watsonville, CA 95076",
      phone: "(831) 786-4700",
      googleMapsURL: "https://goo.gl/maps/kH7PCH48iRgZMPCj6",
    },
  ],
  socialLinks: null,
  button: null,
  linkObj: AppLink,
};

export default Footer;
