import React from "react";

import { Icon, Text } from "@atoms";
import { AppLink } from "@defaults";

const LinkList = ({ heading, subHeading, links, linkObj: LinkObj }) => {
  return (
    <div>
      {heading && (
        <Text variant="mono--small-label" className="text-blue-dark">
          {heading}
        </Text>
      )}
      {subHeading && (
        <Text
          variant="h6"
          className="mb-8 mt-6 font-bold leading-tight text-black"
        >
          {subHeading}
        </Text>
      )}
      <ul className="mt-2">
        {links &&
          links.length > 0 &&
          links.map(link => {
            if (link.link) {
              return (
                <li key={link.link.link} className="mb-4 w-full">
                  <LinkObj
                    to={link.link.link}
                    className="group flex items-center text-blue-dark transition hover:text-orange"
                  >
                    <div className="mr-3 flex-shrink-0 text-blue">
                      <Icon name={link.icon || "page"} className="h-8 w-8" />
                    </div>
                    <Text variant="mono--small-label" className="underline-1">
                      {link.link.label}
                    </Text>
                  </LinkObj>
                </li>
              );
            }
            return null;
          })}
      </ul>
    </div>
  );
};

LinkList.defaultProps = {
  heading: null,
  subHeading: null,
  links: [],
  linkObj: AppLink,
};

export default LinkList;
