import React from "react";

import { StatCard } from "@molecules";
import { Container } from "@atoms";

const StatsGrid = ({ stats, background, id }) => {
  const colors = ["blue", "peach", "yellow", "blue-light"];
  return (
    <div id={id} className={`bg-${background}`}>
      <Container padding className="my-12">
        <div className="-mx-2 flex flex-wrap font-bold">
          {stats.map((stat, i) => {
            if (i < 4) {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <div key={i} className="mb-4 w-full px-2 sm:w-1/2">
                  <StatCard {...stat} color={colors[i]} />
                </div>
              );
            }
            return null;
          })}
        </div>
      </Container>
    </div>
  );
};

StatsGrid.defaultProps = {
  stats: [],
  background: "white",
};

export default StatsGrid;
