"use client";

import React, { useEffect, useState, lazy } from "react";

const Video = lazy(() => import("../base/VideoPlayer"));

const VideoContainer = ({ url, className, fill, ...props }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  if (loaded && typeof url === "string")
    return (
      <Video
        url={url}
        {...props}
        className={(className, { "absolute inset-0": fill })}
      />
    );

  return <div className={className} />;
};

export default VideoContainer;
