import React from "react";

import { Container, Text } from "@atoms";
import { CtaAlt, HoverCard } from "@molecules";

const CtaOptions = ({
  heading,
  // ctaIcon,
  subHeading,
  ctaHeading,
  ctaCopy,
  ctaButton,
  cards,
  id,
}) => {
  return (
    <div id={id} className="cabrillo-block py-12 sm:py-16">
      <Container padding>
        {(heading || subHeading) && (
          <div className="mb-12">
            {heading && (
              <Text variant="mono--large-label" className="mb-1 text-blue">
                {heading}
              </Text>
            )}
            {subHeading && (
              <Text variant="h4" className="font-bold text-blue-dark">
                {subHeading}
              </Text>
            )}
          </div>
        )}
        <div className="my-4">
          <CtaAlt heading={ctaHeading} copy={ctaCopy} button={ctaButton} />
        </div>
        <div className="-mx-2 mt-4 flex flex-wrap">
          {!!cards?.length &&
            cards.map((card, i) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <div key={i} className="mb-4 w-full px-2 sm:w-1/2 md:w-1/3">
                  <HoverCard {...card} />
                </div>
              );
            })}
        </div>
      </Container>
    </div>
  );
};

CtaOptions.defaultProps = {
  heading: null,
  // ctaIcon: null,
  subHeading: null,
  ctaHeading: null,
  ctaCopy: null,
  ctaButton: null,
  cards: null,
};

export default CtaOptions;
