import React from "react";

import { Accordion, CareerInfo } from "@molecules";
import { Text, Icon } from "@atoms";
import Container from "../atoms/Container";

// all accordion components need open prop
const Head = ({ open, heading, id }) => {
  return (
    <div id={id} className="div flex items-center border-t border-peach py-2">
      <div className="flex-shink-0 flex w-16 w-auto justify-end p-2">
        <Icon
          name="dropdown"
          className={`h-8 w-8 transform text-blue-light ${
            open ? "rotate-180" : "rotate-0"
          } transition`}
        />
      </div>
      <div className="flex-grow px-4 text-left">
        <Text variant="body--large" className="font-bold text-white">
          {heading}
        </Text>
      </div>
    </div>
  );
};

// all accordion components need open prop
// eslint-disable-next-line react/display-name
const Content = ({ career, open }) => {
  return (
    <div className="px-16 py-4">
      <CareerInfo career={career} open={open} />
    </div>
  );
};

const AccordionCards = ({
  id,
  heading,
  subHeading,
  sections: sectionsData,
}) => {
  // map populated components to sections array
  const sections = sectionsData
    ? sectionsData
        .filter(section => section.career && section.career.length > 0)
        .map(section => {
          return {
            head: Head,
            headData: {
              heading: section.title,
            },
            content: Content,
            contentData: { career: section.career },
          };
        })
    : false;
  // now the actual component
  return (
    <div id={id} className="cabrillo-block bg-blue py-12 sm:py-16">
      <div className="mx-auto mr-auto w-full max-w-xl px-3">
        {heading && (
          <Text
            variant="h3"
            className="mb-2 w-full text-center text-blue-extralight"
          >
            {heading}
          </Text>
        )}
        {subHeading && (
          <Text
            variant="h4"
            className="mb-12 w-full text-center font-bold leading-tight text-white"
          >
            {subHeading}
          </Text>
        )}
      </div>
      <Container padding>
        {sections && <Accordion sections={sections} />}
      </Container>
    </div>
  );
};

AccordionCards.defaultProps = {
  heading: null,
  subHeading: null,
  sections: null,
};

Content.defaultProps = {
  career: null,
  open: false,
};

Head.defaultProps = {
  heading: null,
  open: false,
};

export default AccordionCards;
