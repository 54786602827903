import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { Text, Button, Container } from "@atoms";
import SiteHeader from "../../components/organisms/SiteHeader";

// todo: use static query

const Announcement = ({ heading, subHeading, link }) => (
  <div className="bg-blue py-6">
    <Container>
      <div className="flex flex-col md:flex-row md:items-center">
        <div className="flex-grow">
          <Text variant="mono--small-label" className="text-yellow">
            {heading}
          </Text>
          <Text variant="h6" className="font-bold leading-tight text-white">
            {subHeading}
          </Text>
        </div>
        {!!link.length && (
          <div className="flex-shink-0 mt-3 md:my-0">
            <Button to={link}>Learn More</Button>
          </div>
        )}
      </div>
    </Container>
  </div>
);

const AppHeader = ({ data }) => {
  const {
    headerPrimary,
    headerSecondary,
    globalAnnouncement: announcement,
  } = data;
  // get top-level of primary nav
  const primarynav = headerPrimary.links
    // remove last item (becomes button)
    .slice(0, headerPrimary.links.length - 1)
    // filter out children links
    .filter(link => link.parent === "0")
    // return the links w/ empty array
    .map(link => {
      // level 2 (sublinks)
      // filter all links based on parent and attachc to primary link
      const subLinks = headerPrimary.links.filter(subLink => {
        return parseInt(subLink.parent, 10) === parseInt(link.id, 10);
      });
      // level 3 (childlinks)
      // filter child links based on parent and attach to sublinks
      subLinks.forEach((subLink, i) => {
        subLinks[i].links = headerPrimary.links
          .filter(childLink => {
            return parseInt(childLink.parent, 10) === parseInt(subLink.id, 10);
          })
          .map(childLink => {
            if (childLink.label === "[divider]") {
              return {};
            }
            return childLink;
          });
      });
      return { ...link, links: subLinks };
    });
  // primarynav

  const secondarynav = headerSecondary.links;
  const button = headerPrimary.links[headerPrimary.links.length - 1];
  // return component
  return (
    <div className="z-50">
      {(!!announcement.heading.length || !!announcement.subHeading.length) && (
        <Announcement {...announcement} />
      )}
      <SiteHeader
        links={primarynav}
        secondaryLinks={secondarynav}
        button={button}
      />
    </div>
  );
};

// eslint-disable-next-line react/display-name
export default () => (
  <StaticQuery
    query={graphql`
      query HeaderQuery {
        globalAnnouncement {
          heading
          subHeading
          link
        }
        headerPrimary {
          links {
            link
            parent
            id
            label
          }
        }
        headerSecondary {
          links {
            label
            link
          }
        }
      }
    `}
    render={data => <AppHeader data={data} />}
  />
);
