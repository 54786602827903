import React, { useState, useRef, useEffect } from "react";

import useWindowSize from "@hooks/useWindowSize";
import { Container, Text, RichText } from "@atoms";
import { AsymmetricPhotoGridThree } from "@molecules";
import SideBar from "./SideBar";

const IntroWImages = ({
  sideBarImage,
  sideBarSections,
  lead,
  copy,
  images,
  heading,
  family,
  id,
}) => {
  const [minHeight, setMinHeight] = useState("none");
  const sidebarContainer = useRef();
  const { innerWidth: windowSize } = useWindowSize();
  useEffect(() => {
    if (sidebarContainer.current) {
      setMinHeight(sidebarContainer.current.clientHeight - 48);
    }
  }, [windowSize]);
  return (
    <Container>
      <div
        id={id}
        style={{ minHeight }}
        className="relative pt-6 sm:pt-12 lg:py-12"
      >
        <Container padding interior>
          <div
            className={`flex flex-wrap ${
              sideBarSections || sideBarImage ? "lg:pl-32 xl:pl-32" : ""
            } pt-2`}
          >
            <div className="max-w-2xl pr-6 pt-4 md:w-3/5">
              {heading && (
                <Text
                  variant="h4"
                  className="mb-12 font-bold text-blue-dark lg:mt-12"
                >
                  {heading}
                </Text>
              )}
              {lead && (
                <div>
                  <Text
                    variant="body--large"
                    className="rich-text mb-6 leading-normal"
                  >
                    <RichText content={lead} />
                  </Text>
                </div>
              )}
              {copy && (
                <div>
                  <Text variant="body" className="rich-text leading-normal">
                    <RichText content={copy} />
                  </Text>
                </div>
              )}
            </div>

            {/* images */}
            {images && (
              <div className="order-1 mt-6 w-full sm:order-2 md:mt-0 md:w-2/5">
                <div className="-mx-2 -mt-2 pt-6 lg:mx-0 lg:-mr-24 xl:-mr-40">
                  <AsymmetricPhotoGridThree images={images} />
                </div>
              </div>
            )}
          </div>
        </Container>
        {/* sidebar */}
        {(sideBarSections || sideBarImage) && (
          <div className="-mx-3 sm:-mx-6">
            <div
              ref={sidebarContainer}
              className="z-20 order-2 my-6 w-full lg:absolute lg:left-0 lg:top-0 lg:order-1 lg:-mb-20 lg:-ml-12 lg:-mt-6 lg:w-auto"
            >
              <div className="lg:-ml-3">
                <SideBar
                  image={sideBarImage}
                  sections={sideBarSections}
                  family={family}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </Container>
  );
};

IntroWImages.defaultProps = {
  sideBarImage: null,
  sideBarSections: null,
  lead: null,
  copy: null,
  images: [],
  heading: null,
};

export default IntroWImages;
