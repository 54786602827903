import React from "react";

import { Button } from "@atoms";
import { AppLink } from "@defaults";
import { uid } from "react-uid";

const MobileNav = ({ links, button, linkObj: LinkObj }) => {
  return (
    <div className="-mx-3 flex flex-wrap bg-blue-extralight lg:hidden">
      {links &&
        links.map(link => {
          return (
            <div key={uid(link)} className="w-full px-3 sm:w-1/2 md:w-1/3">
              {/* the primary link */}
              <div className="mb-3 mt-6 text-lg font-bold text-blue transition hover:text-blue-light sm:text-xl">
                <LinkObj to={link.link}>{link.label}</LinkObj>
              </div>
              {/* now the sublinks */}
              {link.links &&
                link.links.map(subLink => {
                  return (
                    <div
                      key={uid(subLink)}
                      className="mb-3 text-sm font-normal text-black"
                    >
                      <LinkObj to={subLink.link}>{subLink.label}</LinkObj>
                    </div>
                  );
                })}
            </div>
          );
        })}
      {button && (
        <div className="mb-3 mt-6 w-1/2 w-full px-3 md:w-1/3">
          <Button to={button.link} size="sm" color="orange">
            {button.label}
          </Button>
        </div>
      )}
    </div>
  );
};

MobileNav.defaultProps = {
  linkObj: AppLink,
  links: null,
  button: null,
};

export default MobileNav;
