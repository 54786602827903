import React, { useState, useEffect } from "react";

import Papa from "papaparse";
import { Text, Container } from "@atoms";
import { parse } from "marked";
import { decode } from "html-entities";
import clsx from "clsx";

// todo: use fetch

const TableBlock = ({ csv, url, hasHeader: _hasHeader, id }) => {
  const hasHeader = _hasHeader === "yes" || _hasHeader == null;
  const [values, setValues] = useState(csv ? Papa.parse(csv).data : false);
  const [error, setError] = useState(false);
  useEffect(() => {
    if (!csv) {
      try {
        // TODO: Update url before moving to production
        const processedUrl = url?.includes("https://docs.google.com/")
          ? `${
              process.env.GATSBY_SITE_URL
                ? ""
                : "https://stage-cabrillo.netlify.app"
            }/gs-proxy/${decode(url)?.replace("https://docs.google.com/", "")}`
          : url;
        fetch(processedUrl)
          .then(res => res.text())
          .then(res => {
            setValues(Papa.parse(res).data);
          })
          .catch(e => {
            setError(true);
            // eslint-disable-next-line no-console
            console.log(e);
          });
      } catch (e) {
        setError(true);
        // eslint-disable-next-line no-console
        console.log(e);
      }
    }
  }, []);
  return (
    <Container>
      <div id={id} className="w-full overflow-x-scroll font-mono">
        {values && !error && (
          <table className="w-full table-auto border-l border-r border-t border-blue-light">
            {hasHeader && (
              <thead>
                <tr>
                  {values[0].map((v, i) => {
                    return (
                      // eslint-disable-next-line jsx-a11y/control-has-associated-label
                      <th
                        // eslint-disable-next-line react/no-array-index-key
                        key={i}
                        className="max-w-sm border-b border-blue-light bg-blue-dark px-4 py-2 text-left text-white"
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{ __html: parse(v) }}
                      />
                    );
                  })}
                </tr>
              </thead>
            )}
            <tbody>
              {values.slice(hasHeader ? 1 : 0, values.length).map((v, i) => {
                return (
                  <tr
                    // eslint-disable-next-line react/no-array-index-key
                    key={i}
                    className={clsx("hover:bg-blue-extralight", {
                      "bg-white": i % 2 === 0,
                      "bg-blue-superlight": i % 2 === 1,
                    })}
                  >
                    {v.map((vv, ii) => {
                      return (
                        // eslint-disable-next-line jsx-a11y/control-has-associated-label
                        <td
                          // eslint-disable-next-line react/no-array-index-key
                          key={ii}
                          className="max-w-sm border-b border-blue-light px-4 py-2 text-left text-blue-dark"
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{ __html: parse(vv) }}
                        />
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
        {!values && !error && (
          <Text
            variant="mono--smaller-label"
            className="animate-pulse bg-blue-superlight p-12 text-center text-blue-dark"
          >
            loading...
          </Text>
        )}
        {error && (
          <Text
            variant="mono--smaller-label"
            className="border border-orange bg-blue-superlight p-12 text-center text-orange"
          >
            Error: Table failed to load.
          </Text>
        )}
      </div>
    </Container>
  );
};

TableBlock.defaultProps = {
  csv: null,
  url: null,
  hasHeader: null,
};

export default TableBlock;
