/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect, useRef } from "react";

import clsx from "clsx";
import ImgixClient from "@imgix/js-core";
import useWindowSize from "@hooks/useWindowSize";
import { crest as Crest } from "@svg";
import { m } from "framer-motion";

const Image = ({
  url,
  caption,
  fit,
  alt,
  aspectratio,
  position,
  fill,
  params,
  center,
  noFade,
  imageClassName,
}) => {
  if (url) {
    const [visible, setVisible] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [width, setWidth] = useState("auto");
    const [height, setHeight] = useState("auto");
    const [src, setSrc] = useState(null);
    const { innerWidth: windowSize } = useWindowSize();
    const imgContainer = useRef();
    const transformFade = useRef();

    // get pixel device ratio, set to 1 if window or pdr is undefined
    const pr = typeof window !== "undefined" ? window.devicePixelRatio || 1 : 1;

    // calculate aspect ratio
    let arm;
    if (aspectratio != null) {
      const arSplit = aspectratio.includes("/")
        ? aspectratio.split("/")
        : aspectratio.split(":");
      arm = parseInt(arSplit[1], 10) / parseInt(arSplit[0], 10);
      // ? leaving this here in case it's needed later
      // paddingAdjust = {
      //   paddingBottom: `${((arSplit[1] / arSplit[0]) * 100).toFixed(2)}%`,
      // };
    }

    const fallbacks = {
      GATSBY_IMGIX_DOMAIN: "cabrilloext.imgix.net",
      GATSBY_IMGIX_BASE_URL:
        "https://wp-cabrilloext.s3.us-west-2.amazonaws.com/",
      GATSBY_IMGIX_BASE_URL_ALT:
        "https://s3-us-west-2.amazonaws.com/wp-cabrilloext/",
    };

    // configure imgix
    // get config
    const secureURLToken =
      process.env.STORYBOOK_IMGIX_TOKEN || process.env.GATSBY_IMGIX_TOKEN;
    const domain =
      process.env.STORYBOOK_IMGIX_DOMAIN ||
      process.env.GATSBY_IMGIX_DOMAIN ||
      fallbacks.GATSBY_IMGIX_DOMAIN;
    const processedUrl = secureURLToken
      ? encodeURI(url)
      : url.replace(
          `${
            process.env.GATSBY_IMGIX_BASE_URL || fallbacks.GATSBY_IMGIX_BASE_URL
          }`,
          "/"
        );

    const ixConfig = secureURLToken
      ? {
          secureURLToken,
          domain,
        }
      : { domain };

    // setup client if there is a domain in the config
    let client;
    const uix = ixConfig.domain;
    if (uix) {
      client = new ImgixClient(ixConfig);
    }

    // configure default params
    const defaultParams = {
      auto: "compress,format",
      fit: arm ? "crop" : "max",
      crop: "faces",
      q: 80,
    };

    // merge with params from prop
    const ixParams = {
      ...defaultParams,
      ...params,
    };

    // load placeholder image – helps browser determine image size before it loads
    const lqipSrc = (
      uix
        ? client.buildURL(processedUrl, {
            ...defaultParams,
            w: 200,
            h: arm ? 200 * arm : "auto",
            blend: "#fff",
            "blend-mode": "normal",
            q: 0,
            // h: 300,
          })
        : url
    ).replace(process.env.GATSBY_IMGIX_DOMAIN, process.env.GATSBY_IMAGE_DOMAIN);

    // change the height and width on page resize
    useEffect(() => {
      let _width;
      let _height;
      if (center) {
        const rect = imgContainer.current.getBoundingClientRect();
        const y = (rect.top + rect.bottom) / 2;
        const x = (rect.left + rect.right) / 2;
        const imageCenter = [x, y];
        _width =
          imgContainer.current.clientWidth + 2 * -(imageCenter[0] - center[0]);
        _height =
          imgContainer.current.clientHeight + 2 * -(imageCenter[1] - center[1]);
      } else {
        _width = imgContainer.current.clientWidth;
        _height = imgContainer.current.clientHeight;
      }
      setWidth(_width);
      setHeight(_height);
    }, [windowSize, loaded, center]);
    // check both on resize and after an image is loaded
    // in case there was an issue caluclating dimensions from lqip

    // // TODO: Finish the math
    // // facial recognition

    // posititon facial recognition stuff
    useEffect(() => {
      if (center) {
        fetch(
          client.buildURL(processedUrl, {
            // ...ixParams,
            // w: width,
            // h: height,
            fm: "json",
            faces: 1,
          })
        )
          .then(res => res.json())
          .then(res => {
            const { Faces, PixelHeight, PixelWidth } = res.data;
            const faceCenter = [
              Faces[0].bounds.x + Faces[0].bounds.width / 2,
              Faces[0].bounds.y + Faces[0].bounds.height / 2,
            ];
            const rightBound = PixelHeight - faceCenter[0];
            const bottomBound = PixelWidth - faceCenter[1];
            const maxWidth =
              rightBound > faceCenter[0] ? faceCenter[0] : rightBound;
            const maxHeight =
              bottomBound > faceCenter[1] ? faceCenter[1] : bottomBound;
            // x,y,w,h
            const rect = `${(faceCenter[0] - maxWidth).toFixed(0)},${(
              faceCenter[1] - maxHeight
            ).toFixed(0)},${(maxWidth * 2).toFixed(0)},${(
              maxHeight * 2
            ).toFixed(0)}`;
            if (Faces) {
              ixParams.crop = null;
              ixParams.fit = "max";
              // ixParams.rect = `0,0,${Faces[0].mouth.x * 2},${
              //   Faces[0].mouth.y * 2
              // }`;
              setSrc(
                (uix
                  ? client.buildURL(processedUrl, {
                      ...ixParams,
                      rect,
                      w: width,
                      h: arm ? width * arm : height,
                      dpr: pr,
                    })
                  : url
                ).replace(
                  process.env.GATSBY_IMGIX_DOMAIN,
                  process.env.GATSBY_IMAGE_DOMAIN
                )
              );
            }
            // reterigger onload after getting data
            // setLoaded(loaded);
          })
          .catch(e => {
            // eslint-disable-next-line no-console
            console.log(`${url}\n${e}`);
          });
      } else {
        setSrc(
          (uix
            ? client.buildURL(processedUrl, {
                ...ixParams,
                w: width,
                h: arm ? width * arm : height,
                dpr: pr,
              })
            : url
          ).replace(
            process.env.GATSBY_IMGIX_DOMAIN,
            process.env.GATSBY_IMAGE_DOMAIN
          )
        );
      }
    }, [width, height, url]);

    // return image
    return (
      <m.div
        onViewportEnter={() => setVisible(true)}
        viewport={{ once: true }}
        className={`w-full ${arm ? "h-auto" : "h-full"}`}
      >
        <figure
          ref={imgContainer}
          className={`w-full ${arm ? "h-auto" : "h-full"} relative transition ${caption ? "" : "overflow-hidden"}`}
          // style={paddingAdjust}
        >
          {/* the actual image */}
          {center && !noFade && (
            <div
              ref={transformFade}
              className="fade-to-blue absolute inset-0 z-20"
            />
          )}
          <img
            className={clsx(
              // todo: remove position
              imageClassName,
              `absolute inset-0 z-20 transition duration-300 object-${fill ? "cover" : fit} ${arm ? "h-auto" : "h-full"} w-full transition`,
              position,
              {
                "object-cover": fit === "cover" || !fit || fill,
                "object-contain": fit === "contain" && !fill,
                "scale-110 opacity-0": !(loaded && visible),
              }
            )}
            src={visible ? src : null}
            alt={alt || ""}
            onLoad={() => {
              setLoaded(true);
            }}
          />
          {/* placeholder before image load-in */}
          <div
            className={clsx(
              "absolute inset-0 z-0 flex w-full items-center justify-center border bg-blue-dark transition duration-500",
              {
                "opacity-0": loaded && visible,
              }
            )}
          >
            <div className="icon w-1/2 max-w-xs text-blue opacity-10">
              <Crest />
            </div>
          </div>
          {/* 
          hack to get image dimensions
           we load in an empty version of the image (<1kb) in order to get the exact dimensions
           then we can propely set the width for other operations
           */}
          {!fill && (
            <img
              src={lqipSrc}
              alt=""
              className="relative z-0 w-full opacity-0"
              style={
                arm
                  ? {
                      minHeight:
                        width === "auto" ? null : parseInt(width * arm, 10),
                    }
                  : null
              }
            />
          )}
          {/* load in a caption below just if provided */}
          {caption && (
            <figcaption
              className="absolute bottom-0 left-0 translate-y-full transform pt-3 font-mono text-xs text-black"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: caption }}
            />
          )}
        </figure>
      </m.div>
    );
  }
  return null;
};

Image.defaultProps = {
  url: null,
  caption: null,
  fit: "contain",
  alt: null,
  aspectratio: null,
  position: "center",
  fill: false,
  params: null,
  center: null,
  noFade: false,
};

export default Image;
