import React, { useState } from "react";

import { Container, Icon, Button } from "@atoms";
import { PrimaryNav, MobileNav } from "@molecules";
import { AppLink } from "@defaults";
import { m } from "framer-motion";
import SecondaryNav from "../molecules/SecondaryNav";

const SiteHeader = ({ links, button, secondaryLinks, linkObj: LinkObj }) => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <header className="bg-blue-dark">
      {/* mobile subnav (language + search only) */}
      <div className="w-full lg:hidden">
        <SecondaryNav searchId="search_mobile" />
      </div>
      {/* main layout */}
      <Container className="relative flex items-center md:items-stretch">
        {/* logo */}
        <div className="w-32 flex-shrink-0 py-4 sm:w-48 xl:w-52">
          <LinkObj to="/" className="flex items-end">
            <Icon
              name="logoInverse"
              className="h-24 cursor-pointer"
              fitHeight
            />
            {process.env.GATSBY_IS_EXTENSION && (
              <Icon
                name="extension"
                className="ml-2 h-8 cursor-pointer"
                fitHeight
              />
            )}
          </LinkObj>
        </div>
        {/* navs */}
        <div className="ml-auto flex flex-col justify-center pl-6 sm:justify-between lg:pt-0">
          {/* table + desktop subnav */}
          <div className="relative z-20 hidden justify-end lg:mb-4 lg:flex">
            <SecondaryNav
              searchId="search_desktop"
              links={secondaryLinks}
              linkObj={LinkObj}
            />
          </div>
          {/* desktop nav */}
          <div className="hidden md:mt-auto lg:block">
            <PrimaryNav links={links} button={button} linkObj={LinkObj} />
          </div>
          {/* mobileNav control */}
          <div className="relative z-10 ml-auto sm:mt-6 lg:hidden">
            <Button
              color="white"
              onClick={() => {
                setMobileNavOpen(!mobileNavOpen);
              }}
              outline
            >
              Menu <Icon className="ml-2 h-5 w-5" name="bars" />
            </Button>
          </div>
        </div>
      </Container>
      {/* end main layout */}

      {/* mobilenav */}
      {/* we don't need to follow the a11y suggestions here since it's mobile only  */}
      <m.div
        initial={{ height: 0 }}
        animate={mobileNavOpen ? { height: "auto" } : { height: 0 }}
        className="h-0 overflow-hidden bg-white text-xl font-bold text-blue"
        onClick={() => {
          setMobileNavOpen(!mobileNavOpen);
        }}
      >
        <div className="p-3">
          <Container>
            <MobileNav
              links={links.concat(secondaryLinks)}
              button={button}
              linkObj={LinkObj}
            />
          </Container>
        </div>
      </m.div>
      {/* end mobilenav */}
    </header>
  );
};

SiteHeader.defaultProps = {
  linkObj: AppLink,
  links: null,
  secondaryLinks: null,
  button: null,
};

export default SiteHeader;
