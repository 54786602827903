import React from "react";

import {
  AsymmetricPhotoGridFive,
  ContentWButton,
  AsymmetricPhotoGridThreeAlt,
} from "@molecules";
import { Container, Icon, Image } from "@atoms";

const HeadingWImages = ({
  heading,
  subHeading,
  button,
  images,
  background,
  watermark,
  copy,
  id,
}) => {
  return (
    <div id={id} className={`relative bg-${background} z-20`}>
      {watermark === "show" && (
        <Icon
          name="wordmark"
          className="absolute bottom-0 left-0 right-0 z-0 -m-2 -mb-12 text-white opacity-25"
        />
      )}
      <Container className="relative z-10" padding>
        <div className="-mx-3 flex flex-wrap items-center py-12 sm:-mx-6 md:py-16 lg:py-24">
          <div className="my-12 w-full px-3 sm:px-6 md:w-1/2 lg:w-3/5">
            <ContentWButton
              heading={heading}
              subHeading={subHeading}
              button={button}
              copy={copy}
              singleCol
            />
          </div>
          <div className="my-12 w-full px-3 sm:px-6 md:w-1/2 md:pl-0 lg:w-2/5">
            <div className="sm:-mx-10 md:-mx-12 lg:-mr-20 lg:ml-0 xl:-mr-32">
              {images.length === 1 && <Image {...images[0]} />}
              {/* {images.length === 2 && (
                <AsymmetricPhotoGridTwo images={images} />
              )} */}
              {images.length === 3 && (
                <AsymmetricPhotoGridThreeAlt images={images} />
              )}
              {/* {images.length === 4 && (
                <AsymmetricPhotoGridFour images={images} />
              )} */}
              {images.length === 5 && (
                <AsymmetricPhotoGridFive images={images} />
              )}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

HeadingWImages.defaultProps = {
  images: [],
  heading: null,
  subHeading: null,
  button: { link: "", label: "" },
  watermark: "hide",
  background: "peach",
  copy: null,
};

export default HeadingWImages;
