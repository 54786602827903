import React from "react";

import { Image } from "@atoms";

const AsymmetricPhotoGridFive = ({ images }) => {
  return (
    <div>
      {images && images.length > 0 && (
        <>
          <div className="flex w-full">
            {/* top left two */}
            <div className="flex w-2/5 flex-col items-end justify-end pl-6">
              <div className="w-full p-2">
                {images[0] && (
                  <Image
                    {...images[0].image}
                    aspectratio="2:3"
                    position="object-right-bottom"
                  />
                )}
              </div>
              <div className="w-4/5 p-2">
                {images[1] && (
                  <Image
                    {...images[1].image}
                    aspectratio="4:3"
                    position="object-right"
                  />
                )}
              </div>
            </div>
            {/* top right one */}
            <div className="w-3/5 p-2 pr-12">
              {images[2] && (
                <Image
                  {...images[2].image}
                  // aspectratio="4:3"
                  position="object-left-bottom"
                />
              )}
            </div>
          </div>
          {/* bottom two */}
          <div className="flex">
            <div className="w-3/5 p-2">
              {images[3] && (
                <Image
                  {...images[3].image}
                  aspectratio="8:5"
                  position="object-right-top"
                />
              )}
            </div>
            <div className="w-2/5 p-2">
              {images[4] && (
                <Image
                  {...images[4].image}
                  aspectratio="4:3"
                  position="object-left-top"
                />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

AsymmetricPhotoGridFive.defaultProps = {
  images: [],
};

export default AsymmetricPhotoGridFive;
