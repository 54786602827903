import React, { useRef, useEffect, useState } from "react";
import { m, useAnimation } from "framer-motion";
import clsx from "clsx";
import useWindowSize from "@hooks/useWindowSize";
import { Icon } from "@atoms";

const Carousel = ({
  children,
  className: _className,
  indicators,
  hug,
  maxVisible = 1,
  align = "top",
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slideWidth, setSlideWidth] = useState(0);
  const [visibleSlides, setVisibleSlides] = useState(maxVisible);
  const slides = React.Children.map(children, (child, i) => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        index: i,
      });
    }
    return child;
  });

  const slideCount = slides.length;
  const carouselControls = useAnimation();
  const { innerWidth: windowWidth } = useWindowSize();
  const carouselContainer = useRef();

  // from tailwind config
  const screenSizes = {
    sm: "700",
    md: "850",
    lg: "1200",
  };

  // configure number of slides based on screen size
  const noSlides = {
    sm: Math.max(maxVisible - 2, 1),
    md: Math.max(maxVisible - 1, 2),
    lg: maxVisible,
  };

  const handleDrag = (event, info) => {
    const { x, y } = info.offset;
    const { x: velocity } = info.velocity;
    if (Math.abs(x) > Math.abs(y)) {
      requestAnimationFrame(() => {
        if (x < -slideWidth / slideCount || velocity < -400) {
          setCurrentSlide(prevState => {
            if (prevState < slides.length - visibleSlides) {
              return prevState + 1;
            }
            carouselControls.start({
              x: `-${(currentSlide / visibleSlides / slideCount) * 100}%`,
            });
            return prevState;
          });
        } else if (x > slideWidth / slideCount || velocity > 400) {
          setCurrentSlide(prevState => {
            if (prevState > 0) {
              return prevState - 1;
            }
            carouselControls.start({
              x: `-${(currentSlide / visibleSlides / slideCount) * 100}%`,
            });
            return prevState;
          });
        } else {
          carouselControls.start({
            x: `-${(currentSlide / visibleSlides / slideCount) * 100}%`,
          });
        }
      });
    }
  };

  // calculate # of slides that are visible
  const calculateVisibleSlides = width => {
    if (maxVisible > 1) {
      // match screen
      const matchedScreen = Object.keys(screenSizes).find(screen => {
        return width < screenSizes[screen];
      });
      // return match
      if (matchedScreen && noSlides[matchedScreen] <= maxVisible) {
        return noSlides[matchedScreen];
      }
    }
    return maxVisible;
  };

  useEffect(() => {
    carouselControls.start({
      x: `-${(currentSlide / visibleSlides / slideCount) * 100}%`,
    });
  }, [currentSlide]);

  // change slide width on window resize
  useEffect(() => {
    if (carouselContainer.current) {
      requestAnimationFrame(() => {
        setSlideWidth(carouselContainer.current.clientWidth);
      });
    }
  }, [carouselContainer, windowWidth, visibleSlides]);

  // calculate visible slides on window resize
  useEffect(() => {
    if (carouselContainer.current) {
      requestAnimationFrame(() => {
        const newSlides = calculateVisibleSlides(windowWidth);
        setVisibleSlides(newSlides);
      });
    }
  }, [windowWidth]);

  // reset currentSlide every time the number of visible slides changes
  useEffect(() => {
    setCurrentSlide(0);
  }, [visibleSlides]);

  return (
    <>
      <div
        ref={carouselContainer}
        className={clsx("relative w-full", _className)}
      >
        {/* prev button */}
        {slideCount > 1 && (
          <div
            className={clsx(
              "absolute bottom-0 top-0 z-10 hidden items-center justify-center md:flex",
              {
                "-left-6 md:-left-[96px]": !hug,
                "-left-6 md:-left-[32px]": hug,
              }
            )}
          >
            <button
              className={clsx(
                "bg-primary text-background group flex h-10 w-10 items-center justify-center duration-500 md:h-[64px] md:w-[64px]",
                {
                  "opacity-0": currentSlide <= 0,
                }
              )}
              type="button"
              onClick={() => {
                setCurrentSlide(prevState => {
                  if (prevState > 0) {
                    return prevState - 1;
                  }
                  return prevState;
                });
              }}
              aria-label="Go to the previous slide"
            >
              <Icon
                name="arrow"
                className="relative h-6 w-6 rotate-180 duration-500 md:h-[48px] md:w-[48px]"
              />
            </button>
          </div>
        )}
        <m.div
          animate={carouselControls}
          className={clsx("flex", {
            "items-center": align === "center",
            "items-start": align === "top",
          })}
          transition={{ duration: 0.5, type: "tween" }}
          style={{ width: `${slideCount * 100}%` }}
          drag={slideCount > 1 ? "x" : false}
          onDragEnd={handleDrag}
          dragConstraints={{ left: "-100%", right: 0 }}
          dragDirectionLock
        >
          {slides.map((slide, i) => (
            <div
              key={slide.key}
              className={clsx("relative duration-500", {
                "opacity-0":
                  i < currentSlide || i + 1 > currentSlide + visibleSlides,
              })}
              style={{ width: `${(1 / visibleSlides / slideCount) * 100}%` }}
            >
              {slide}
            </div>
          ))}
        </m.div>
        {/* next button */}
        {slideCount > 1 && (
          <div
            className={clsx(
              "absolute bottom-0 top-0 z-10 hidden items-center justify-center md:flex",
              {
                "-right-16 lg:-right-[96px]": !hug,
                "-right-6 lg:-right-[32px]": hug,
              }
            )}
          >
            <button
              className={clsx(
                "bg-primary text-background group flex h-10 w-10 items-center justify-center duration-500 md:h-[64px] md:w-[64px]",
                {
                  "opacity-0": currentSlide >= slideCount - visibleSlides,
                }
              )}
              type="button"
              onClick={() => {
                setCurrentSlide(prevState => {
                  if (prevState < slideCount - visibleSlides) {
                    return prevState + 1;
                  }
                  return prevState;
                });
              }}
              aria-label="Go to the next slide"
            >
              <Icon
                name="arrow"
                className="relative h-6 w-6 duration-500 md:h-[48px] md:w-[48px]"
              />
            </button>
          </div>
        )}
      </div>
      {/* indicators */}
      {indicators && slideCount > 1 && (
        <ul className="mt-[32px] flex items-center justify-center gap-2">
          {slides.map((slide, i) => (
            <li key={slide.key}>
              <button
                type="button"
                onClick={() => setCurrentSlide(i)}
                className={clsx(
                  "border-primary text-background block h-4 w-4 rounded-full border duration-500",
                  {
                    "bg-primary": currentSlide === i,
                  }
                )}
                aria-label={`Go to slide ${i + 1}`}
              >
                <span className="hidden">{i}</span>
              </button>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export default Carousel;
