import React from "react";

import { Button } from "@atoms";

const ButtonBlock = ({ link, id }) => {
  if (link) {
    return (
      <div id={id} className="flex w-full items-center justify-center p-6">
        <Button to={link?.link}>{link?.label}</Button>
      </div>
    );
  }
  return null;
};

ButtonBlock.defaultProps = {};

export default ButtonBlock;
