import React from "react";

import { Container, Image } from "@atoms";
import { Cta } from "@molecules";

const EndOfPageCta = ({
  image,
  heading,
  subHeading,
  primaryButton,
  secondaryButton,
  background,
  decoration,
  id,
}) => {
  return (
    <>
      <div
        id={id}
        className="relative z-10 flex flex-col items-center justify-end"
      >
        {/* background image */}
        <div className="min-h-1/3-screen" />
        <div className="absolute inset-0 z-0">
          <div className="absolute inset-0 z-0">
            <Image {...image} fill />
          </div>
        </div>
        <Container>
          <Cta
            heading={heading}
            subHeading={subHeading}
            primaryButton={primaryButton}
            secondaryButton={secondaryButton}
            background={background}
            decoration={decoration}
          />
        </Container>
      </div>
      {/* background stripe */}
      <div className="h-36 bg-blue-dark" />
    </>
  );
};

EndOfPageCta.defaultProps = {
  image: null,
  heading: null,
  subHeading: null,
  primaryButton: null,
  secondaryButton: null,
  background: "peach",
  decoration: "crest",
};

export default EndOfPageCta;
