import React from "react";

import { Container, Text, Button } from "@atoms";

const TwoColumnsCta = ({ columns, background, id }) => {
  return (
    <div id={id} className={`bg-${background} py-12 sm:py-16`}>
      <Container padding>
        <div className="-mx-3 flex flex-wrap sm:-mx-6 md:-mx-12">
          {columns &&
            columns.length > 0 &&
            columns.map((col, i) => {
              const hasBorder = i !== columns.length - 1 && i % 2 === 0;
              return (
                <div
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  className={`w-full px-3 py-6 pr-12 sm:px-6 sm:py-12 sm:pr-24 md:w-1/2 md:px-12 ${
                    hasBorder
                      ? `md:border-r-2 ${
                          background === "blue"
                            ? "border-blue-light"
                            : "border-blue"
                        }`
                      : ""
                  }`}
                >
                  <Text
                    variant="mono--small-label"
                    className={`${
                      background === "blue" ? "text-white" : "text-blue"
                    }`}
                  >
                    {col.heading}
                  </Text>
                  <Text
                    variant="xl"
                    className={`mb-8 mt-2 font-bold ${background === "blue" ? "text-white" : "text-blue-dark"}`}
                  >
                    {col.subHeading}
                  </Text>
                  {col.button && (
                    <div className="-mx-1 flex flex-wrap">
                      <div className="mb-3 w-auto px-2">
                        <Button color="yellow" to={col.button.link} size="sm">
                          {col.button.label}
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      </Container>
    </div>
  );
};

TwoColumnsCta.defaultProps = { columns: [], background: "blue-extralight" };

export default TwoColumnsCta;
