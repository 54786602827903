import React from "react";
import { StaticQuery, graphql } from "gatsby";
import SiteFooter from "../../components/organisms/SiteFooter";

// todo: use static query

const AppHeader = ({ data }) => {
  const {
    footerPrimary: primaryNav,
    footerSecondary: secondaryNav,
    footerButton: button,
    footerSocial: socialLinks,
  } = data;

  return (
    <div>
      <SiteFooter
        primaryNav={primaryNav.links}
        secondaryNav={secondaryNav.links}
        button={button.links[0]}
        // locations={[]}
        socialLinks={socialLinks.links}
      />
    </div>
  );
};

// eslint-disable-next-line react/display-name
export default () => (
  <StaticQuery
    query={graphql`
      query FooterQuery {
        footerPrimary {
          links {
            label
            link
          }
        }
        footerSecondary {
          links {
            label
            link
          }
        }
        footerSocial {
          links {
            label
            link
          }
        }
        footerButton {
          links {
            label
            link
          }
        }
      }
    `}
    render={data => <AppHeader data={data} />}
  />
);
