import React from "react";

import { Container, Text, Lines, SearchBar } from "@atoms";
import { LinkListLarge } from "@molecules";
import { AppLink } from "@defaults";

const QuickLinks = ({ heading, links, linkObj: LinkObj, id }) => {
  return (
    <Container
      padding
      className="relative bg-blue py-6 sm:py-12 md:py-16 lg:py-24"
    >
      <div id={id} className="flex flex-wrap">
        <div className="w-full md:w-2/3">
          <Text variant="mono--small-label" className="text-blue-superlight">
            {heading}
          </Text>
          <LinkListLarge links={links} linkObj={LinkObj} />
        </div>
        <div className="mt-3 w-full md:mt-0 md:w-1/3">
          <SearchBar id="quick_search" />
        </div>
        <div className="pointer-events-none absolute right-0 h-full w-2/3 text-blue-superlight">
          <Lines />
        </div>
      </div>
    </Container>
  );
};

QuickLinks.defaultProps = {
  linkObj: AppLink,
  heading: "Quick Links",
  links: [],
};

export default QuickLinks;
