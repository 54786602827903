import React from "react";

import { decodeString } from "@utils";

const Email = ({ email }) => {
  if (typeof window !== "undefined") {
    return decodeString(email);
  }
  return <span>loading...</span>;
};

Email.defaultProps = { email: null };

export default Email;
