import { registerMiddleware } from "../lib/middleware";

const reducer = (state, action) => {
  switch (action.type) {
    case "setTranslation":
      return {
        ...state,
        lang: action.lang,
        translations: action.translations,
      };
    case "setLang":
      return {
        ...state,
        lang: action.lang,
      };

    default:
      return state;
  }
};

// const { hasGdprConsent, setGdprConsent } = gdpr;

registerMiddleware({
  actionType: "acceptGdpr",
});

const initialState = {
  lang: null,
  translations: {},
};

export default {
  reducer,
  initialState,
};
