import React, { useEffect } from "react";

import { Text, Button } from "@atoms";
import { uid } from "react-uid";
import moment from "moment";
import { useAppState } from "@state/state";
import ModalEvent from "./ModalEvent";

const EventListSmall = ({ events }) => {
  const [{ modal: _modal }, dispatch] = useAppState();
  const { modal } = _modal;

  const hasWindow = typeof window !== "undefined";
  const url = hasWindow ? new URL(window?.location) : null;
  const params = new URLSearchParams(url?.search);
  const hasEvent = params?.get("event");

  const openModal = event => {
    const { id } = event;

    if (hasWindow) {
      window.history.pushState(
        {},
        "",
        `${url?.origin}${url?.pathname}?event=${id}`
      );
    }

    dispatch({
      type: "openModal",
      content: <ModalEvent {...event} url={url} />,
    });
  };

  useEffect(() => {
    if (hasWindow) {
      const urlEvent = events?.filter(e => e.id === hasEvent)?.[0] || null;

      if (!modal && hasEvent && urlEvent) {
        openModal(urlEvent);
      }
    }
  }, [hasEvent]);

  useEffect(() => {
    if (!modal && hasEvent) {
      if (hasWindow) {
        window.history.pushState({}, "", `${url?.origin}${url?.pathname}`);
      }
    }
  }, [modal, hasEvent]);

  const resolveDates = (startDate, endDate) => {
    const sd = startDate ? moment(startDate) : false;
    const ed = endDate ? moment(endDate) : false;
    if (sd && ed) {
      if (sd.format("MMDDYYYY") === ed.format("MMDDYYYY")) {
        return `${sd.format("MMMM D @ h:mma")} - ${ed.format("h:mma")}`;
      }
      return `${sd.format("MMMM D @ h:mma")} - ${ed.format("MMMM D @ h:mma")}`;
    }
    if (sd) {
      return `${sd.format("MMMM D @ h:mma")}`;
    }
    return false;
  };

  if (events) {
    return events.slice(0, 5).map(event => {
      const { title, building, roomNumber, roomName, category, start, end } =
        event;

      const dateTimes = resolveDates(start, end);

      return (
        <>
          <div className="h-px bg-pink opacity-50" />
          <div key={uid(event)} className="py-4">
            {category && !!category.length && (
              <Text
                variant="mono--extra-small"
                className="mb-3 text-blue-light"
              >
                {category}
              </Text>
            )}
            <Text variant="body" className="mt-3 font-bold text-blue">
              {title}
            </Text>
            {dateTimes && (
              <div className="my-1 font-mono text-xs uppercase text-black">
                {dateTimes}
              </div>
            )}
            <span className="mt-1 text-xs opacity-75">
              {building} {roomNumber}
            </span>
            {roomName && roomName !== roomNumber && (
              <span className="mt-1 text-xs opacity-75">{roomName}</span>
            )}
          </div>
          <Button
            type="button"
            size="xs"
            className="mb-4"
            onClick={() => openModal(event, dateTimes)}
          >
            Event details
          </Button>
        </>
      );
    });
  }
  return null;
};

EventListSmall.defaultProps = {
  events: null,
};

export default EventListSmall;
