import React from "react";

import { Text, RichText } from "@atoms";

const IconWCopy = ({ number, heading, copy }) => {
  return (
    <>
      <Text variant="mono--small-label" className="font-bold text-blue">
        {String(number + 1).padStart(2, "0")}
      </Text>
      {heading && (
        <Text variant="body--large" className="my-2 font-bold text-blue">
          {heading}
        </Text>
      )}
      {copy && (
        <Text variant="body--small" className="rich-text mt-2 text-black">
          <RichText content={copy} />
        </Text>
      )}
    </>
  );
};

IconWCopy.defaultProps = {
  number: null,
  heading: null,
  copy: null,
};

export default IconWCopy;
