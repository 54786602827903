import React from "react";

import { Image, Text } from "@atoms";
import { AppLink } from "@defaults";

const SideBarPerson = ({ image, name, title, link }) => {
  const ixParams = {
    fit: "facearea",
    facepad: 2,
  };
  return (
    <AppLink to={link || null} className={`-mx-1 flex ${link ? "group" : ""}`}>
      <div className="px-1">
        <div className="relative h-10 w-10 overflow-hidden rounded-full">
          <Image {...image} params={ixParams} fill />
        </div>
      </div>
      <div className="p-1">
        <Text
          variant="xs"
          className="mb-1 font-bold text-blue group-hover:text-orange"
        >
          {name}
        </Text>
        <Text
          variant="xs"
          className="font-bold text-blue-dark group-hover:text-orange"
        >
          {title}
        </Text>
      </div>
    </AppLink>
  );
};

SideBarPerson.defaultProps = {
  image: null,
  name: null,
  title: null,
  website: null,
};

export default SideBarPerson;
