import React from "react";

import { AppLink } from "@defaults";
import { Text } from "@atoms";
import { uid } from "react-uid";

const SmallLinkList = ({ links, linkObj: LinkObj }) => {
  if (links) {
    return links.map(link => {
      return (
        <div key={uid(link)} className="mb-2 w-full">
          <LinkObj
            to={link.link}
            className="block text-xs font-bold text-white transition hover:text-yellow"
          >
            <Text variant="xs" className="hidden leading-tight sm:block">
              {link.label}
            </Text>
            <Text variant="body" className="leading-tight sm:hidden">
              {link.label}
            </Text>
          </LinkObj>
        </div>
      );
    });
  }
  return null;
};

SmallLinkList.defaultProps = { links: null, linkObj: AppLink };

export default SmallLinkList;
