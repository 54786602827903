import React from "react";

import { Text, Image, Icon, Container, RichText } from "@atoms";
import { decodeString, removeNonNumeric, formatPhone } from "@utils";

const AnnouncementWImage = ({ heading, phone, email, copy, image, id }) => {
  return (
    <div id={id} className="bg-blue">
      <Container padding>
        <div className="-mx-3 flex flex-wrap items-center py-12 sm:py-16">
          {/* copy */}
          <div className="w-full px-3 md:w-3/5">
            {heading && heading.length > 1 && (
              <Text variant="h5" className="mb-8 text-white">
                {heading}
              </Text>
            )}
            {copy && (!!copy?.length || typeof copy === "object") && (
              <Text variant="body--small" className="mb-3 text-white">
                <RichText content={copy} />
              </Text>
            )}
            {(phone || email) && (
              <div className="-mx-3 flex flex-wrap">
                {phone && (!!copy?.length || typeof copy === "object") && (
                  <a
                    href={`tel:+1${removeNonNumeric(phone)}`}
                    className="my-3 w-auto px-3 text-white hover:text-orange"
                  >
                    <div className="flex flex-row items-center">
                      <div className="mr-1 flex-shrink-0 text-blue-light">
                        <Icon name="phone" className="h-6 w-6" />
                      </div>
                      <Text
                        variant="mono--extra-small-label"
                        className="text-white"
                      >
                        {formatPhone(removeNonNumeric(phone))}
                      </Text>
                    </div>
                  </a>
                )}
                {email && (
                  <a
                    href={`mailto:${
                      typeof window !== "undefined" ? decodeString(email) : ""
                    }`}
                    className="my-3 w-auto px-3 text-white hover:text-orange"
                  >
                    <div className="flex flex-row items-center">
                      <Icon
                        name="email"
                        className="mr-1 h-6 w-6 p-1 text-blue-light"
                      />
                      <Text
                        variant="mono--extra-small"
                        className="underline-1 -mt-1"
                      >
                        {decodeString(email)}
                      </Text>
                    </div>
                  </a>
                )}
              </div>
            )}
          </div>
          {/* image */}
          <div
            className="relative w-full px-3 md:w-2/5"
            style={{
              minHeight: "400px",
            }}
          >
            <div className="absolute inset-0">
              <Image {...image} fill />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

AnnouncementWImage.defaultProps = {
  heading: null,
  phone: null,
  email: null,
  copy: null,
  image: null,
};

export default AnnouncementWImage;
