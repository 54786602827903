import React from "react";

const Text = ({ children, className: _className, variant }) => {
  let Tag = "div";
  let classes = "text-base";
  switch (variant) {
    case "h1":
      Tag = "h1";
      classes = "text-h2 sm:text-h1 leading-none font-sans";
      break;
    case "h2":
      Tag = "h2";
      classes = "text-h3 sm:text-h2 leading-none font-sans";
      break;
    case "h3":
      Tag = "h3";
      classes = "text-h4 sm:text-h3 leading-none font-sans";
      break;
    case "h4":
      Tag = "h4";
      classes = "text-h5 sm:text-h4 leading-none font-sans";
      break;
    case "h5":
      Tag = "h5";
      classes = "text-h6 sm:text-h5 leading-none font-sans";
      break;
    case "h6":
      Tag = "h6";
      classes = "text-xl sm:text-h6 leading-none font-sans";
      break;
    case "xl":
      classes = "text-xl font-sans";
      break;
    case "lg":
      classes = "text-lg font-sans";
      break;
    case "body":
      classes = "text-base leading-normal font-sans";
      break;
    case "base":
      classes = "text-base font-sans";
      break;
    case "sm":
      classes = "text-sm font-sans";
      break;
    case "xs":
      classes = "text-xs font-sans";
      break;
    case "xxs":
      classes = "text-xxs font-sans";
      break;
    case "body--small":
      classes = "text-sm leading-normal font-sans";
      break;
    case "body--large":
      classes = "text-lg sm:text-xl leading-normal font-sans";
      break;
    case "mono--h1":
      Tag = "h1";
      classes = "text-h1-mono leading-none font-mono tracking-1";
      break;
    case "mono--large-label":
      classes = "text-xl leading-tight font-mono";
      break;
    case "mono--small-label":
      classes = "text-base leading-tight font-mono";
      break;
    case "mono--smaller-label":
      classes = "text-sm leading-tight font-mono";
      break;
    case "mono--extra-small-label":
      classes = "text-xs leading-tight font-mono";
      break;
    case "mono--dynamic":
      classes = "text-xs leading-normal font-mono";
      break;
    case "mono--extra-small":
      classes = "text-xs leading-normal font-mono";
      break;
    default:
    // do nothing
  }
  return React.Children.toArray(children).map((child, i) => {
    // if children are not components (html or string), then render the html
    if (typeof child.type === "undefined") {
      return (
        <Tag
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          className={`${classes} ${_className}`}
          dangerouslySetInnerHTML={{ __html: child }}
        />
      );
    }
    // otherwise return children
    return (
      // eslint-disable-next-line react/no-array-index-key
      <Tag key={i} className={`${classes} ${_className}`}>
        {child}
      </Tag>
    );
  });
};

Text.defaultProps = {
  tag: "div",
  className: null,
  variant: null,
};

export default Text;
