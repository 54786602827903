import React, { useEffect, useState } from "react";

import { Text } from "@atoms";

const CareerInfo = ({ career: slug, open }) => {
  const apiKey = process.env.GATSBY_CAREER_COACH_API_KEY;
  const [career, setCareer] = useState({});
  useEffect(() => {
    fetch(
      `https://api.emsicc.com/careers/us/state/6/${slug}?fields=median-earnings,description,core-tasks,annual-openings`,
      {
        method: "GET",
        headers: {
          // "Content-Type": "application/x-www-form-urlencoded",
          "X-API-KEY": apiKey || "74c57e54667342aa9083fec173c9f603",
        },
      }
    )
      .then(response => response.json())
      .then(data => {
        setCareer(data.data.attributes);
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log(e);
      });
  }, []);

  if (
    career &&
    career.description &&
    career["median-earnings"] &&
    career["core-tasks"]
  ) {
    return (
      <>
        <div className="mb-3 flex flex-wrap lg:flex-nowrap">
          <div className="w-auto">
            <div className="mb-6 font-sans leading-normal text-white">
              {career.description}
            </div>
          </div>
          <div
            className={`mb-3 ml-3 flex-shrink-0 text-center lg:-mr-16 lg:ml-6 ${
              open ? "lg:-mt-20" : "lg:-mt-0"
            } transition-all`}
          >
            <div className="flex flex-col items-center bg-white px-6 py-6 md:py-8">
              <div className="mb-2 flex items-center font-sans text-xl font-bold text-blue">
                <div className="flex">
                  $
                  {parseInt(
                    career["median-earnings"].toFixed(0),
                    10
                  ).toLocaleString()}
                </div>
              </div>
              <div className="text-center font-mono text-xs text-blue-light">
                Median Earnings in CA
              </div>
              <div className="my-6 h-px w-full bg-blue-light lg:w-56 xl:w-64" />
              <div className="mb-2 flex items-center font-sans text-xl font-bold text-blue">
                <div className="flex">
                  {career["annual-openings"].toLocaleString()}
                </div>
              </div>
              <div className="text-center font-mono text-xs text-blue-light">
                Annual Openings in CA
              </div>
            </div>
          </div>
        </div>

        <div className="w-full">
          <Text variant="body" className="mb-1 font-bold text-white">
            Core Tasks
          </Text>
          {career["core-tasks"]
            .filter((task, i) => i < 3)
            .map((task, i) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <Text key={i} variant="body--small" className="mb-1 text-white">
                  <div className="flex">
                    <div className="px-2 text-blue-light">•</div>
                    <div>{`${task}`}</div>
                  </div>
                </Text>
              );
            })}
        </div>
      </>
    );
  }
  return null;
};

CareerInfo.defaultProps = {
  career: null,
  open: false,
};

export default CareerInfo;
