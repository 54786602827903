import React from "react";

const Lines = ({ position, fewer }) => {
  let classes;
  let transform;
  switch (position) {
    case "top-right":
      classes = "top-0 right-0";
      transform = "transform -scale-y-1";
      break;
    case "top-left":
      classes = "top-0 left-0";
      transform = "transform -scale-1";
      break;
    case "bottom-left":
      classes = "bottom-0 left-0";
      transform = "transform -scale-x-1";
      break;
    // bottom-right
    default:
      classes = "bottom-0 right-0";
      transform = "";
      break;
  }
  return (
    <div
      className={`absolute w-full fill-current opacity-60 ${classes} ${transform}`}
    >
      <svg
        viewBox="0 0 812 424"
        className="block w-full transform"
        xmlns="http://www.w3.org/2000/svg"
      >
        {!fewer && (
          <>
            <path
              opacity="0.3"
              d="M0.00141275 422.04L810.726 -3.07417e-05L810.726 2.25988L4.33141 422.038L0.00141275 422.04Z"
            />
            <path
              opacity="0.3"
              d="M57.6313 422.038L810.724 30.0005L810.723 32.2604L61.9613 422.038L57.6313 422.038Z"
            />
            <path
              opacity="0.3"
              d="M115.256 422.04L119.586 422.04L810.721 62.2611L810.721 60.0018L115.256 422.04Z"
            />
            <path
              opacity="0.3"
              d="M172.891 422.04L177.221 422.04L810.721 92.2635L810.721 90.0047L172.891 422.04Z"
            />
            <path
              opacity="0.3"
              d="M230.511 422.04L234.852 422.04L810.721 122.256L810.721 119.997L230.511 422.04Z"
            />
            <path
              opacity="0.3"
              d="M810.721 152.256L810.721 149.997L288.14 422.04L292.48 422.04L810.721 152.256Z"
            />
            <path
              opacity="0.3"
              d="M810.721 182.256L810.721 179.997L345.721 422.064L350.062 422.064L810.721 182.256Z"
            />
          </>
        )}
        <path
          opacity="0.3"
          d="M810.721 212.256L810.721 209.997L403.397 422.04L407.737 422.04L810.721 212.256Z"
        />
        <path
          opacity="0.3"
          d="M810.721 242.256L810.721 239.997L461.025 422.04L465.366 422.04L810.721 242.256Z"
        />

        <path
          opacity="0.3"
          d="M810.721 272.256L810.721 269.997L518.654 422.04L522.994 422.04L810.721 272.256Z"
        />
        <path
          opacity="0.3"
          d="M810.721 302.256L810.721 299.997L576.282 422.04L580.623 422.04L810.721 302.256Z"
        />
        <path
          opacity="0.3"
          d="M810.721 332.256L810.721 329.997L633.911 422.04L638.251 422.04L810.721 332.256Z"
        />
        <path
          opacity="0.3"
          d="M810.721 362.256L810.721 359.997L691.539 422.04L695.88 422.04L810.721 362.256Z"
        />
        <path
          opacity="0.3"
          d="M810.721 392.256L810.721 389.997L749.168 422.04L753.508 422.04L810.721 392.256Z"
        />
        <path
          opacity="0.3"
          d="M810.721 422.04L810.721 419.997L806.796 422.04L810.721 422.04Z"
        />
      </svg>
    </div>
  );
};

Lines.defaultProps = {
  position: "bottom-right",
  fewer: false,
};

export default Lines;
