import React from "react";

import { Container } from "@atoms";
import { StatCard, ContentWButton } from "@molecules";

const HeadingWStats = ({ heading, subHeading, button, stats, id }) => {
  const colors = ["blue-dark", "yellow", "blue", "blue-light", "peach"];
  return (
    <Container className="relative z-10 py-12 sm:py-16" padding>
      <div id={id} className="mb-12">
        <ContentWButton
          heading={heading}
          subHeading={subHeading}
          button={button}
          variant="lg"
        />
      </div>
      {stats && (
        <div className="-mx-2 flex flex-wrap font-bold">
          {/* first card */}
          {stats[0] && (
            <div className="mb-4 w-full px-2 md:w-3/5">
              <StatCard {...stats[0]} color={colors[0]} />
            </div>
          )}
          {/* second card */}
          {stats[1] && (
            <div className="mb-4 w-full px-2 md:w-2/5">
              <StatCard {...stats[1]} color={colors[1]} />
            </div>
          )}
          {/* third card */}
          {stats[2] && (
            <div className="mb-4 w-full px-2 md:w-1/3">
              <StatCard {...stats[2]} color={colors[2]} />
            </div>
          )}
          {/* fourth card */}
          {stats[3] && (
            <div className="mb-4 w-full px-2 md:w-1/3">
              <StatCard {...stats[3]} color={colors[3]} />
            </div>
          )}
          {/* fifth card */}
          {stats[4] && (
            <div className="mb-4 w-full px-2 md:w-1/3">
              <StatCard {...stats[4]} color={colors[4]} />
            </div>
          )}
        </div>
      )}
    </Container>
  );
};

HeadingWStats.defaultProps = {
  heading: null,
  subHeading: null,
  button: null,
  stats: null,
};

export default HeadingWStats;
