import React from "react";

import { Text, Container, Image, Button, RichText } from "@atoms";
import { AppLink } from "@defaults";

const CapOverview = ({
  heading,
  subHeading,
  copy,
  button,
  image,
  links,
  background,
  linkObj: LinkObj,
  id,
}) => {
  let headingColor = "white";
  let subHeadingColor = "blue-dark";
  let textColor = "blue-dark";
  let highlightColor = "white";
  switch (background) {
    case "blue-light":
      headingColor = "blue";
      subHeadingColor = "blue-dark";
      textColor = "blue-dark";
      highlightColor = "white";
      break;
    case "blue-dark":
      headingColor = "blue-extralight";
      subHeadingColor = "white";
      textColor = "white";
      highlightColor = "blue-light";
      break;
    case "blue-extralight":
      headingColor = "blue";
      subHeadingColor = "blue-dark";
      textColor = "blue-dark";
      highlightColor = "orange";
      break;
    case "peach":
      headingColor = "blue";
      subHeadingColor = "blue-dark";
      textColor = "blue-dark";
      highlightColor = "orange";
      break;
    case "yellow":
      headingColor = "blue";
      subHeadingColor = "blue-dark";
      textColor = "blue-dark";
      highlightColor = "blue";
      break;
    default:
      break;
  }
  return (
    <div
      id={id}
      className={`cabrillo-block bg-${background} py-12 sm:py-16 md:py-20`}
    >
      <Container padding>
        {/* heading + subHeading */}
        {heading && heading.length > 1 && (
          <Text
            variant="mono--small-label"
            className={`text-${headingColor} mb-3`}
          >
            {heading}
          </Text>
        )}
        {subHeading && subHeading.length > 1 && (
          <Text
            variant="h4"
            className={`font-bold text-${subHeadingColor} mb-3`}
          >
            {subHeading}
          </Text>
        )}
        {/* copy + button */}
        <div className="-mx-3 flex flex-wrap items-center justify-between md:flex-nowrap">
          {copy && (!!copy.length || typeof copy === "object") && (
            <Text
              variant="body"
              className={`rich-text mb-3 px-3 text-${textColor}`}
            >
              <RichText content={copy} />
            </Text>
          )}
          {button && (
            <div className="mt-6 w-auto flex-shrink-0 px-3">
              <Button to={button.link} size="sm" color="orange">
                {button.label}
              </Button>
            </div>
          )}
        </div>
        {/* links + image */}
        <div className="-mx-3 mt-6 flex flex-wrap items-start">
          <div className="w-full px-3 md:w-2/3 lg:w-3/5 xl:w-1/2">
            <div className="two-columns -mx-3">
              {links &&
                links.length > 0 &&
                links.map((item, i) => {
                  const { link } = item;
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={i} className="mb-6 w-full px-3">
                      <LinkObj
                        to={link.link}
                        className={`text-${textColor} text-xl font-bold leading-tight sm:text-2xl hover:text-${highlightColor} transition`}
                      >
                        {link.label}
                      </LinkObj>
                    </div>
                  );
                })}
            </div>
          </div>
          <div
            className="relative w-full px-3 md:w-2/3 lg:w-2/5 xl:w-1/2"
            style={{
              minHeight: "300px",
            }}
          >
            <Image {...image} />
          </div>
        </div>
      </Container>
    </div>
  );
};

CapOverview.defaultProps = {
  heading: null,
  subHeading: null,
  copy: null,
  button: null,
  image: null,
  links: null,
  background: "blue-light",
  linkObj: AppLink,
};

export default CapOverview;
