import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import clsx from "clsx";
import { search as Eyeglass, close as Close } from "@svg";
import { m } from "framer-motion";

const SearchSmall = ({
  canSearch,
  searchFor,
  onQueryChange,
  initialQuery,
  id,
}) => {
  const [term, setTerm] = useState(initialQuery);

  useEffect(() => {
    setTerm(initialQuery);
  }, [initialQuery]);

  const handleKeyPress = e => {
    if (e.key === "Enter") {
      if (canSearch) {
        if (searchFor) {
          searchFor(term);
        } else {
          navigate(`/search/?q=${term}`);
        }
      }
    }
  };

  const handleSubmit = () => {
    if (canSearch) {
      if (searchFor) {
        searchFor(term);
      } else {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        navigate(`/search/?q=${term}`);
      }
    }
  };

  useEffect(() => {
    if (onQueryChange) {
      onQueryChange(term);
    }
  }, [term]);
  const [open, setOpen] = useState(false);

  return (
    <div className="size-6 bg-blue-medium">
      <m.div
        className="absolute bottom-0 right-0 top-0 flex items-center justify-start bg-blue-medium"
        initial={{ left: "auto", background: "transparent", color: "white" }}
        animate={
          !open
            ? // todo: use tailwind black
              { left: "auto", background: "transparent", color: "white" }
            : { left: 0, background: "#fff", color: "black" }
        }
      >
        {/* search button */}
        <button
          type="button"
          className="relative z-20 p-3"
          onClick={() => {
            if (!open) {
              setOpen(true);
            } else {
              handleSubmit();
            }
          }}
        >
          <span className="sr-only">{!open ? "open" : "submit"}</span>
          <div className="icon size-5 fill-current">
            <Eyeglass />
          </div>
        </button>

        {/* input */}
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor={id || "search_small"} className="sr-only">
          search
        </label>
        <input
          id={id || "search_small"}
          name={id || "search_small"}
          placeholder="Enter search query"
          onKeyPress={handleKeyPress}
          onChange={e => {
            setTerm(e.target.value);
          }}
          className="placeholder-mono w-0 flex-grow bg-transparent font-mono text-base placeholder-white transition focus:opacity-100"
        />

        {/* close button */}
        <button
          type="button"
          disabled={!open}
          className={clsx("absolute right-0 z-10 p-3 transition duration-300", {
            "opacity-0": !open,
          })}
          onClick={() => setOpen(false)}
        >
          <span className="sr-only">close</span>
          <div className="icon h-4 w-4 fill-current">
            <Close />
          </div>
        </button>
      </m.div>
    </div>
  );
};

SearchSmall.defaultProps = {
  inverse: true,
  canSearch: true,
  searchFor: null,
  onQueryChange: null,
  initialQuery: "",
};

export default SearchSmall;
