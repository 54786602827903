import React from "react";

import { Image, Text } from "@atoms";
import {
  SideBarIcon,
  SideBarPerson,
  SideBarHours,
  FamilyNav,
} from "@molecules";
import { AppLink } from "@defaults";

const SideBar = ({
  image,
  sections,
  linkObj: LinkObj,
  className: _className,
  family,
}) => {
  // eslint-disable-next-line react/no-unstable-nested-components, react/display-name
  const Section = ({ section }) => {
    switch (section.type) {
      case "relativeNav":
        return (
          <div className="mb-3">
            <FamilyNav family={family} />
          </div>
        );
      case "links":
        if (section.items && section.items.length) {
          return section.items.map((item, i) => {
            if (item.link) {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <div key={i} className="mb-3">
                  <LinkObj
                    className="mb-1 text-blue transition hover:text-orange"
                    to={item.link.link}
                  >
                    <Text variant="xs" className="font-bold">
                      {item.link.label}
                    </Text>
                  </LinkObj>
                </div>
              );
            }
            return null;
          });
        }
        return null;

      case "people":
        if (section.items && section.items.length) {
          return section.items.map((item, i) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <div key={i} className="mb-3">
                <SideBarPerson {...item} />
              </div>
            );
          });
        }
        return null;

      case "icons":
        if (section.items && section.items.length) {
          return section.items.map((item, i) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <div key={i} className="mb-3">
                <SideBarIcon {...item} />
              </div>
            );
          });
        }
        return null;

      case "hours":
        if (section.items && section.items.length) {
          return section.items.map((item, i) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <div key={i} className="mb-3">
                <SideBarHours {...item} />
              </div>
            );
          });
        }
        return null;

      default:
        return null;
    }
  };

  return (
    <div
      className={`left-0 w-full bg-blue-superlight px-6 lg:w-48 lg:px-2 xl:w-56 ${_className}`}
    >
      <div className="relative -mx-2 flex flex-wrap px-3 py-6">
        {image && (
          <div className="order-2 mb-3 ml-auto w-full px-3 sm:w-1/3 lg:order-1 lg:w-full">
            <Image {...image} />
          </div>
        )}
        {sections &&
          sections.length &&
          sections?.map((section, i) => {
            return (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                className="order-1 mb-6 w-full px-2 xxs:px-0 sm:w-1/3 lg:order-2 lg:w-full"
              >
                {section.heading && section.heading.length > 1 && (
                  <Text
                    variant="mono--smaller-label"
                    className="underline-start mb-4 text-black"
                  >
                    {section.heading}
                  </Text>
                )}
                <Section section={section} />
              </div>
            );
          })}
      </div>
      <div className="visible border-t-2 border-dashed border-orange border-opacity-30 py-2 text-center md:invisible">
        <div className="mb-4 font-mono text-sm leading-tight text-orange">
          Learn More &#x25BC;
        </div>
      </div>
    </div>
  );
};

SideBar.defaultProps = { image: null, sections: null, linkObj: AppLink };

export default SideBar;
