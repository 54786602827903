import createAppState from "./lib/createAppState";
// import search from "./reducers/search";
import translation from "./reducers/translation";
import modal from "./reducers/modal";

const reducer = {
  // layout: search.reducer,
  translation: translation.reducer,
  modal: modal.reducer,
};

const initialState = {
  // layout: search.initialState,
  translation: translation.initialState,
  modal: modal.initialState,
};

export const { AppStateProvider, AppStateConsumer, useAppState } =
  createAppState(reducer, initialState);

export default {
  AppStateProvider,
  AppStateConsumer,
  useAppState,
};
