import React, { Suspense } from "react";

import { Container, Text, Video, Lines } from "@atoms";

const VideoWHeading = ({ heading, subHeading, url, background, id }) => {
  return (
    <div id={id} className={`py-12 sm:py-16 bg-${background} relative`}>
      <div className="pointer-events-none absolute inset-0 z-0 w-full text-white md:w-2/3">
        <Lines position="object-top-left" />
      </div>
      <Container className="relative z-10 py-12 sm:py-16" padding>
        <div className="relative z-10 mb-10 text-center">
          {heading && (
            <Text variant="mono--large-label" className="text-blue">
              {heading}
            </Text>
          )}
          {subHeading && (
            <Text
              variant="h4"
              className="font-bold leading-tight text-blue-dark"
            >
              {subHeading}
            </Text>
          )}
        </div>
        <div className="w-full">
          <Suspense fallback={<div />}>
            <Video
              className="absolute inset-0"
              aspectRatio={16 / 9}
              url={url}
            />
          </Suspense>
        </div>
      </Container>
    </div>
  );
};

VideoWHeading.defaultProps = {
  heading: null,
  subHeading: null,
  url: null,
  background: "blue-extralight",
};

export default VideoWHeading;
