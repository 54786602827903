import React from "react";

import { AppLink } from "@defaults";
import { Text } from "@atoms";
import { uid } from "react-uid";

const LinkListMedium = ({ links, linkObj: LinkObj }) => {
  if (links) {
    return links.map(link => {
      return (
        <LinkObj
          to={link.link}
          key={uid(link)}
          className="mb-4 block font-bold text-white transition hover:text-yellow"
        >
          <Text variant="body" className="hidden leading-tight sm:block">
            {link.label}
          </Text>
          <Text variant="body--large" className="leading-tight sm:hidden">
            {link.label}
          </Text>
        </LinkObj>
      );
    });
  }
  return null;
};

LinkListMedium.defaultProps = {
  links: null,
  linkObj: AppLink,
};

export default LinkListMedium;
