import React, { useState, useEffect, useRef } from "react";

import useWindowSize from "@hooks/useWindowSize";
import { Container, Text, Button, Image, Icon } from "@atoms";

const Testimonial = ({
  quote,
  citation,
  image,
  button,
  align,
  layout,
  backgroundBottom,
  backgroundTop,
  id,
}) => {
  const [shieldOrigin, setShieldOrigin] = useState(null);
  const { innerWidth: windowSize } = useWindowSize();
  const shield = useRef();
  useEffect(() => {
    if (shield.current) {
      const rect = shield.current.getBoundingClientRect();
      const y = (rect.top + rect.bottom) / 2;
      const x = (rect.left + rect.right) / 2;
      setShieldOrigin([x, y]);
    }
  }, [windowSize]);
  let ixParams = {
    exp: -1,
    con: -10,
    sat: 25,
    bri: -5,
  };
  if (layout === "mask") {
    ixParams = {
      fit: "facearea",
      facepad: 5,
    };
    return (
      <div id={id} className={`relative z-20 py-12 bg-${backgroundBottom}`}>
        <div
          className={`absolute left-0 right-0 top-0 bg-${backgroundTop}`}
          style={{ bottom: "50%" }}
        />
        <div className="relative z-10">
          <div>
            <Container padding className="relative overflow-hidden bg-blue">
              <div
                className="z-10 -mx-3 flex sm:-mx-6"
                style={{ minHeight: "400px" }}
              >
                <div
                  className={`flex w-full flex-col justify-center px-3 py-6 sm:px-6 sm:py-12 md:w-2/3 md:py-16 lg:w-1/2 lg:py-20 ${
                    align === "left" ? "order-2" : ""
                  } relative z-10`}
                >
                  <div>
                    <Text
                      variant="h6"
                      className="mb-6 mt-0 font-bold leading-tight text-white"
                    >
                      {quote}
                    </Text>
                    {citation && (
                      <Text variant="mono--small-label" className="text-yellow">
                        {citation}
                      </Text>
                    )}
                    {button && (
                      <div className="mt-6">
                        <Button to={button.link}>{button.label}</Button>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className={`absolute inset-0 flex items-center justify-center px-3 sm:px-6 md:relative md:w-1/3 lg:w-1/2 ${
                    align === "left" ? "order-1" : ""
                  }`}
                >
                  <div
                    className="absolute bottom-0 right-0 top-0 z-10"
                    ref={shield}
                  >
                    <Icon
                      name="mask"
                      className="relative z-10 h-full text-blue md:opacity-100"
                      fitHeight
                    />
                    <div className="absolute inset-0 z-0">
                      <Image
                        {...image}
                        params={ixParams}
                        center={shieldOrigin}
                        noFade
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={`relative py-12 bg-${backgroundBottom}`}>
      <div
        className={`absolute left-0 right-0 top-0 bg-${backgroundTop}`}
        style={{ bottom: "50%" }}
      />
      <Container padding className="relative z-10 bg-blue-dark">
        <div className="absolute inset-0 z-0">
          {/* <div
            className={`absolute top-0 right-0 bottom-0 w-full testimonial-fade z-10 ${
              align === "left" ? "transform -scale-1" : ""
            }`}
          /> */}
          <div
            className={`absolute ${align === "left" ? "left-0" : "right-0"} bottom-0 top-0 z-0 md:w-1/3 lg:w-1/2`}
          >
            <Image
              {...image}
              params={ixParams}
              center={shieldOrigin}
              fill
              fit="cover"
              testimonial
            />
          </div>
        </div>
        <div className="relative py-6 sm:py-12 md:py-16 lg:py-24">
          <div className="relative z-10 -mx-3 flex flex-wrap items-center sm:-mx-6">
            <div
              className={`w-full px-3 sm:px-6 md:w-2/3 lg:w-1/2 ${
                align === "left" ? "order-2" : ""
              }`}
            >
              <Text
                variant="h6"
                className="mb-6 font-bold leading-tight text-white"
              >
                {quote}
              </Text>
              {citation && (
                <Text variant="mono--small-label" className="mb-6 text-yellow">
                  {citation}
                </Text>
              )}
              {button && <Button to={button.link}>{button.label}</Button>}
            </div>

            <div
              className={`absolute inset-0 flex items-center justify-center px-3 sm:px-6 md:relative md:w-1/3 lg:w-1/2 ${
                align === "left" ? "order-1" : ""
              }`}
            >
              <div
                className="flex h-full w-full items-center justify-center"
                ref={shield}
              />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

Testimonial.defaultProps = {
  quote: null,
  citation: null,
  image: null,
  button: null,
  align: "right",
  layout: "full",
  backgroundBottom: "white",
  backgroundTop: "white",
};

export default Testimonial;
