import React from "react";

import { Image } from "@atoms";

const AsymmetricPhotoGridThree = ({ images }) => {
  return (
    <div>
      {images && images.length > 0 && (
        <div className="flex w-full flex-wrap justify-center md:justify-end">
          {/* bottom two */}
          <div className="flex w-1/2 md:w-full">
            <div className="w-2/5 p-2">
              {images[0] && (
                <Image {...images[0].image} position="object-right-top" />
              )}
            </div>
            <div className="w-3/5 p-2">
              {images[1] && (
                <Image {...images[1].image} position="object-left-top" />
              )}
            </div>
          </div>
          <div className="w-1/2 p-2 md:w-4/5">
            {images[2] && (
              <Image {...images[2].image} position="object-right-top" />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

AsymmetricPhotoGridThree.defaultProps = {
  images: [],
};

export default AsymmetricPhotoGridThree;
