import React from "react";

import { AppLink } from "@defaults";
import { Text } from "@atoms";
import { uid } from "react-uid";

const LinkListMedium = ({ links, linkObj: LinkObj }) => {
  if (links && links.length) {
    return links.map(item => {
      const { link } = item;
      if (link) {
        return (
          <LinkObj
            key={uid(link)}
            to={link.link}
            className="mt-6 block font-bold text-white transition hover:text-yellow md:mt-10"
          >
            <Text variant="h5" className="hidden sm:block">
              {link.label}
            </Text>
            <Text variant="h6" className="sm:hidden">
              {link.label}
            </Text>
          </LinkObj>
        );
      }
      return null;
    });
  }
  return null;
};

LinkListMedium.defaultProps = {
  links: null,
  linkObj: AppLink,
};

export default LinkListMedium;
