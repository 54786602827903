import React from "react";

import { Icon } from "@atoms";
import { uid } from "react-uid";
import { AppLink } from "@defaults";

const SocialLinks = ({ links, linkObj: LinkObj }) => {
  if (links) {
    return (
      <div className="mt-12 flex flex-wrap pl-4">
        {links.map(link => {
          return (
            <LinkObj
              key={uid(link)}
              to={link.link}
              className="mb-3 mr-4 block text-white transition hover:text-yellow"
            >
              <Icon
                name={link.label ? link.label.toLowerCase() : "none"}
                className="h-5 w-5"
              />
            </LinkObj>
          );
        })}
      </div>
    );
  }
  return null;
};

SocialLinks.defaultProps = { links: null, linkObj: AppLink };

export default SocialLinks;
