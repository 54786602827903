import React from "react";

import { Container } from "@atoms";
import { NumberWCopy, ContentWButton } from "@molecules";

const IconGrid = ({ heading, subHeading, copy, icons, id }) => {
  return (
    <div id={id} className="bg-blue-extralight py-12 sm:py-16">
      <Container padding>
        <div className="mb:10 sm:mb-12">
          <ContentWButton
            heading={heading}
            subHeading={subHeading}
            copy={copy}
          />
        </div>
        <div className="-mx-2 flex flex-wrap">
          {icons &&
            icons.length > 0 &&
            icons.map((icon, i) => {
              const { heading: _heading, copy: _copy } = icon;
              return (
                // eslint-disable-next-line react/no-array-index-key
                <div key={i} className="mb-8 w-full px-2 sm:w-1/2 lg:w-1/3">
                  <NumberWCopy number={i + 1} heading={_heading} copy={_copy} />
                </div>
              );
            })}
        </div>
      </Container>
    </div>
  );
};

IconGrid.defaultProps = {
  heading: null,
  subHeading: null,
  copy: null,
  icons: null,
};

export default IconGrid;
