import React from "react";

import { Icon, Button, Text, Lines, Container, RichText } from "@atoms";

const CtaAlt = ({ icon, heading, copy, button }) => {
  return (
    <div className="relative overflow-hidden bg-blue py-12">
      <Container padding>
        <div className="pointer-events-none absolute bottom-0 right-0 z-0 w-4/5 text-blue-extralight">
          <Lines fewer />
        </div>
        <div className="relative z-10 flex flex-col items-center justify-center text-white">
          {icon && <Icon name={icon} className="my-4 w-24 text-blue-light" />}
          {heading && (
            <Text variant="h5" className="my-4 font-bold">
              {heading}
            </Text>
          )}
          {copy && (
            <Text
              variant="body--large"
              className="my-4 font-bold leading-normal"
            >
              <RichText content={copy} inverse />
            </Text>
          )}
          {button && (
            <div className="my-4">
              <Button to={button.link}>{button.label}</Button>
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

CtaAlt.defaultProps = {
  icon: "crest",
  heading: null,
  copy: null,
  button: null,
};

export default CtaAlt;
