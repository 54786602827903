import React from "react";

import { Image, Text } from "@atoms";
import { theme } from "@tailwind";

const StatCard = ({ stat, label, image, color }) => {
  // assumes default is blue
  let blendMode = "multiply";
  let blendColor = theme.colors.blue;
  let textColor = "white";
  switch (color) {
    case "blue":
      // this is actually the default state
      break;
    case "blue-dark":
      blendMode = "multiply";
      blendColor = theme.colors.blue;
      textColor = "white";
      break;
    case "blue-light":
      blendMode = "multiply";
      blendColor = theme.colors.blue;
      textColor = "blue-dark";
      break;
    case "peach":
      blendMode = "multiply";
      blendColor = theme.colors.blue;
      textColor = "blue-dark";
      break;
    case "yellow":
      blendMode = "multiply";
      blendColor = theme.colors.blue;
      textColor = "blue-dark";
      break;
    default:
      break;
  }
  const ixParams = {
    "blend-alpha": 100,
    "blend-mode": blendMode,
    "blend-color": blendColor,
  };
  return (
    <div className={`relative p-6 bg-${color} group h-full w-full`}>
      {image && (
        <div
          className="absolute inset-0 z-0 opacity-0 transition group-hover:opacity-50"
          style={{ mixBlendMode: "multiply" }}
        >
          <Image url={image} params={ixParams} fill />
        </div>
      )}
      <div className="relative z-10 flex flex-col justify-between">
        <Text variant="h1" className={`text-${textColor}`}>
          {stat}
        </Text>
        <Text variant="h6" className={`mt-12 text-${textColor}`}>
          {label}
        </Text>
      </div>
    </div>
  );
};

StatCard.defaultProps = { stat: null, label: null, image: null, color: "blue" };

export default StatCard;
