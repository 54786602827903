import React from "react";

import { Text, Image, Icon } from "@atoms";
import { formatPhone, removeNonNumeric } from "@utils";
import { AppLink } from "@defaults";

const IconCard = ({
  image,
  heading,
  phone,
  address,
  campusMap,
  parkingInfo,
  appointment,
  linkObj: LinkObj,
}) => {
  return (
    <div className="flex flex-col">
      <div className="relative">
        <div className="relative z-0">
          <Image {...image} aspectratio="8/5" />
        </div>
      </div>

      <Text variant="body--large" className="my-6 font-bold text-blue">
        {heading}
      </Text>

      <div>
        <div className="flex">
          {/* address + phone */}
          <div className="flex w-full flex-col pr-3 sm:w-1/2">
            {/* phone */}
            {phone && phone.length > 1 && (
              <div className="text-blue-dark transition">
                <div className="flex flex-row items-center">
                  <div className="mr-1 flex-shrink-0 text-blue-dark">
                    <Icon name="phone" className="h-6 w-6" />
                  </div>
                  <Text variant="mono--extra-small-label" className="text-blue">
                    Phone:
                  </Text>
                </div>
                <div className="-mt-1 flex flex-row pl-1">
                  <div className="ml-6 flex-grow">
                    <a
                      href={`tel:+1${removeNonNumeric(phone)}`}
                      className="my-1 mb-2 flex w-1/2 items-center px-1 font-sans text-sm font-bold text-blue-dark hover:text-orange md:w-full"
                    >
                      {formatPhone(removeNonNumeric(phone))}
                    </a>
                  </div>
                </div>
              </div>
            )}

            {/* address */}
            {address && address.length > 1 && (
              <div className="text-blue-dark transition">
                <div className="flex flex-row items-center">
                  <div className="mr-1 flex-shrink-0 text-blue-dark">
                    <Icon name="marker" className="h-6 w-6" />
                  </div>
                  <Text variant="mono--extra-small-label" className="text-blue">
                    Address:
                  </Text>
                </div>
                <div className="-mt-1 flex flex-row pl-1">
                  <div className="ml-6 flex-grow">
                    <Text
                      variant="sm"
                      className="font-bold leading-tight text-blue-dark"
                    >
                      {address}
                    </Text>
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* other links */}
          <div className="flex w-full flex-col pr-3 sm:w-1/2">
            {/* Map Link */}
            {campusMap && campusMap?.link?.length > 1 && (
              <LinkObj
                to={campusMap?.link}
                className="group mb-3 text-blue transition hover:text-orange"
              >
                <div className="flex flex-row items-center">
                  <div className="mr-1 flex-shrink-0 text-blue-dark">
                    <Icon name="search" className="h-6 w-6" />
                  </div>
                  <Text
                    variant="mono--extra-small-label"
                    className="underline-1"
                  >
                    {campusMap.label}
                  </Text>
                </div>
              </LinkObj>
            )}
            {/* Parking Info */}
            {parkingInfo && (
              <LinkObj
                to={parkingInfo?.link}
                className="group mb-3 text-blue transition hover:text-orange"
              >
                <div className="flex flex-row items-center">
                  <div className="mr-1 flex-shrink-0 text-blue-dark">
                    <Icon name="parking" className="h-6 w-6" />
                  </div>
                  <Text
                    variant="mono--extra-small-label"
                    className="underline-1"
                  >
                    {parkingInfo.label}
                  </Text>
                </div>
              </LinkObj>
            )}
            {/* Schedule a Tour */}
            {appointment && appointment?.link?.length > 1 && (
              <LinkObj
                to={appointment?.link}
                className="group mb-3 text-blue transition hover:text-orange"
              >
                <div className="flex flex-row items-center">
                  <div className="mr-1 flex-shrink-0 text-blue-dark">
                    <Icon name="event" className="h-6 w-6" />
                  </div>
                  <Text
                    variant="mono--extra-small-label"
                    className="underline-1"
                  >
                    {appointment.label}
                  </Text>
                </div>
              </LinkObj>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

IconCard.defaultProps = {
  image: null,
  heading: null,
  phone: null,
  address: null,
  campusMap: null,
  parkingInfo: null,
  appointment: null,
  linkObj: AppLink,
};

export default IconCard;
