import React, { useEffect, useState } from "react";
import { AppLink } from "@defaults";
import { Text } from "@atoms";
import { decode } from "html-entities";

const CodeBlock = ({ heading, code: _code, id, scripts }) => {
  const code = decode(_code); // decodeURIComponent(_code);
  const validator = html => {
    const doc = document.createElement("div");
    doc.innerHTML = html;
    return doc.innerHTML === html;
  };
  const [isValid, setIsValid] = useState(null);

  useEffect(() => {
    try {
      const result = validator(code);
      setIsValid(result);
    } catch (error) {
      setIsValid(false);
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, []);

  useEffect(() => {
    if (scripts && scripts.length) {
      scripts.forEach(script => {
        setTimeout(() => {
          const s = document.createElement("script");
          s.setAttribute("src", script.script);
          document.getElementsByTagName("head")[0].appendChild(s);
        });
      });
    }
  }, []);

  try {
    return (
      <>
        {!isValid && !process.env.GATSBY_WORDPRESS_URL && (
          <div id={id}>
            <div className="w-full lg:w-1/2">
              {heading && (
                <Text variant="h3" className="mb-2">
                  {heading}
                </Text>
              )}
            </div>
            <Text
              variant="mono--smaller-label"
              className="mb-3 border border-orange bg-blue-superlight p-12 text-center text-orange"
            >
              <span>
                &quote;Code is invalid. Check your html, make sure you use
                validate by direct input XHTML 1.0 and code fragment{" "}
                <AppLink
                  className="underline-1"
                  to="https://validator.w3.org/#validate_by_input"
                >
                  here
                </AppLink>
                .
              </span>
            </Text>
          </div>
        )}
        {/* eslint-disable-next-line react/no-danger */}
        <div id={id} className="container mx-auto mt-4">
          <div className="w-full lg:w-1/2">
            {heading && (
              <Text
                variant="mono--small-label"
                className="mb-2 text-blue-light"
              >
                {heading}
              </Text>
            )}
          </div>
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: code }} />
        </div>
      </>
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    return null;
  }
};

CodeBlock.defaultProps = {
  heading: null,
  scripts: null,
  script: null,
};

export default CodeBlock;
