import React from "react";

import { Text, DropDown } from "@atoms";

const DirectoryFilters = ({
  departmentFilter: departmentFilterState,
  departments,
  lNameFilter: lNameFilterState,
  id,
}) => {
  const [lNameFilter, setLNameFilter] = lNameFilterState;
  const [departmentFilter, setDepartmentFilter] = departmentFilterState;

  const alphabet = String("abcdefghijklmnopqrstuvwxyz").split("");
  return (
    <div
      id={id}
      className="max-w-md bg-blue-superlight px-10 py-6 lg:w-48 lg:px-3 xl:w-56"
    >
      <Text
        variant="mono--smaller-label"
        className="underline-start mb-4 text-orange"
      >
        Filter By
      </Text>
      <div className="text-xs font-bold">
        <DropDown
          blue
          model={departmentFilterState}
          placeholder="Department"
          options={departments}
          size="xs"
        />
      </div>
      <div className="mt-6">
        <Text variant="mono--smaller-label" className="mb-2">
          Last Name
        </Text>
        <div className="mb-3 flex flex-wrap">
          {alphabet.map(letter => {
            return (
              <div
                key={letter}
                className="flex h-6 w-6 items-center justify-center p-1"
              >
                <button
                  type="button"
                  onClick={() => {
                    setLNameFilter(letter);
                  }}
                  className={`w-full text-center font-sans font-bold text-blue-dark hover:text-orange ${lNameFilter === letter ? "underline-1" : ""}`}
                >
                  {letter.toUpperCase()}
                </button>
              </div>
            );
          })}
        </div>
      </div>
      <div>
        <button
          type="button"
          onClick={() => {
            setDepartmentFilter(null);
            setLNameFilter(null);
          }}
          className={`underline-1 font-sans text-xs font-bold leading-tight text-blue transition ${
            departmentFilter || lNameFilter ? "opacity-100" : "opacity-0"
          }`}
        >
          Clear Filters
        </button>
      </div>
    </div>
  );
};

DirectoryFilters.defaultProps = {
  departmentFilter: null,
  departments: [],
  lNameFilter: null,
};

export default DirectoryFilters;
