import React from "react";

import { Lines, Text } from "@atoms";
import { AppLink } from "@defaults";

const HoverCard = ({ heading, copy, link, linkObj: LinkObj }) => {
  if (link?.link) {
    return (
      <LinkObj
        to={link.link}
        className="group relative block flex h-full min-h-300 flex-col justify-end overflow-hidden bg-blue-extralight p-6"
      >
        <div className="absolute left-0 right-0 top-0 z-0 h-2 bg-blue transition-all group-hover:h-full" />
        <div className="scale-2 absolute inset-0 bottom-0 left-0 right-0 z-10 -mb-24 transform transition-all group-hover:mb-0">
          <Lines />
        </div>
        <div className="relative z-20 translate-y-6 transform pt-6 text-blue-dark transition group-hover:translate-y-0 group-hover:text-white">
          <Text variant="lg" className="mb-2 font-bold leading-tight">
            {heading}
          </Text>
          <Text variant="body--small" className="my-2">
            {copy}
          </Text>
          <div className="flex h-6 items-center opacity-0 transition group-hover:opacity-100">
            <div className="underline-1 font-mono text-sm text-white">
              {link.label}
            </div>
          </div>
        </div>
      </LinkObj>
    );
  }
  return null;
};

HoverCard.defaultProps = {
  heading: null,
  copy: null,
  link: null,
  linkObj: AppLink,
};

export default HoverCard;
