import React from "react";

import { Container, Text } from "@atoms";
import { Card, EventListSmall, ContentWButton } from "@molecules";

const ImportantAnnouncements = ({
  heading,
  subHeading,
  eventsHeading,
  cards,
  events,
  id,
}) => {
  return (
    <div id={id} className="relative bg-blue-extralight py-12">
      {/* <div className="absolute top-0 right-0 p-6 z-0">
        <Icon name="crest" className="text-blue-extralight opacity-50 w-64" />
      </div> */}

      <Container className="relative z-10" padding>
        <div className="-mx-3 flex flex-wrap sm:-mx-6">
          {/* return the rest of the cards off to the right */}
          <div className="w-full px-3 sm:w-1/2 sm:px-6 md:w-2/3">
            {heading && (
              <div className="mb-12">
                <ContentWButton heading={heading} subHeading={subHeading} />
              </div>
            )}
            <div className="-mx-2 flex flex-wrap">
              {!!cards?.length &&
                cards.map((card, i) => {
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={i} className="mb-8 w-full px-2 md:w-1/2">
                      <Card {...card} size="sm" ar="8:5" />
                    </div>
                  );
                })}
            </div>
          </div>

          <div className="sm:1/2 w-full md:w-1/3">
            {events && (
              <div>
                <Text variant="mono--small-label" className="mb-16">
                  {eventsHeading}
                </Text>
                <EventListSmall events={events} />
              </div>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

ImportantAnnouncements.defaultProps = {
  heading: null,
  subHeading: null,
  eventsHeading: null,
  cards: null,
  events: null,
};

export default ImportantAnnouncements;
