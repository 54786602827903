import React from "react";

import {
  QuickLinks,
  EndOfPageCta,
  ImportantAnnouncements,
  HeadingWImages,
  Testimonial,
  HeadingWStats,
  LinkListColumns,
  CardCarousel,
  CardGrid,
  CardsWCta,
  VideoWHeading,
  DetailedLinkList,
  ImageCarousel,
  FeaturedPeople,
  TwoColumnCta,
  ThreeColumnEvents,
  ThreeColumnCards,
  AnnouncementWImage,
  CapOverview,
  CtaOptions,
  StatsGrid,
  AnnouncementShort,
  ImportantDates,
  IconGrid,
  Copy,
  CardsLocations,
  HeadingWImageAndCopy,
  HeadingWCopy,
  AnnouncementWContactInfo,
  ContentWCarousel,
  AccordionRichText,
  AccordionCards,
  AccordionCareers,
  HeroHome,
  HeroSimple,
  IntroWImages,
  IntroWLinks,
  IntroTwoColumns,
  RelativeNav,
  ButtonBlock,
  CodeBlock,
  TableBlock,
} from "@organisms";
import { PersonCard } from "@molecules";
import { Container } from "@atoms";
import { decode } from "html-entities";

const ResolveBlocks = ({ blocks, family }) => {
  if (blocks && blocks.length > 0) {
    return blocks.map(block => {
      if (
        block &&
        (block?.attributes?.settings || block?.attrs?.settings) &&
        (block.name || block.blockName)
      ) {
        const name = block.name || block.blockName;
        // parse the stringified JSON into object
        let data = {};
        const json = decode(
          block.attributes ? block.attributes.settings : block.attrs.settings
        )
          // replace invalid whitespace characters
          .replace(/(\n|\r)/g, "\\n")
          .replace(/\n/g, "\\n")
          .replace(/\t/g, "\\t")
          .replace(/\s/g, " ");
        // ! unescaped " handled serverside - check wordpress-cabrillo's inc/blocks.php
        try {
          data = JSON.parse(json);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(e, json);
          return null;
        }
        // switch the used component based on component name
        // replace redundant text for more readable code
        switch (name ? name.replace("wordpress-cabrillo/", "") : null) {
          case "person":
            return (
              <Container>
                <PersonCard
                  id={data?.meta?.anchor}
                  {...data}
                  website={data?.website?.link}
                />
              </Container>
            );
          case "three-column-cards":
            return <ThreeColumnCards id={data?.meta?.anchor} {...data} />;
          case "icon-grid":
            return <IconGrid id={data?.meta?.anchor} {...data} />;
          case "copy":
            return <Copy id={data?.meta?.anchor} {...data} />;
          case "location-cards":
            return <CardsLocations id={data?.meta?.anchor} {...data} />;
          case "heading-w-image-and-copy":
            return <HeadingWImageAndCopy id={data?.meta?.anchor} {...data} />;
          case "heading-w-copy":
            return <HeadingWCopy id={data?.meta?.anchor} {...data} />;
          case "announcement-w-contact-info":
            return (
              <AnnouncementWContactInfo id={data?.meta?.anchor} {...data} />
            );
          case "content-w-carousel":
            return <ContentWCarousel id={data?.meta?.anchor} {...data} />;
          case "accordion-careers":
            return <AccordionCareers id={data?.meta?.anchor} {...data} />;
          case `image-carousel`:
            return <ImageCarousel id={data?.meta?.anchor} {...data} />;
          case `featured-people`:
            return <FeaturedPeople id={data?.meta?.anchor} {...data} />;
          case `two-column-cta`:
            return <TwoColumnCta id={data?.meta?.anchor} {...data} />;
          case `three-column-events`:
            return <ThreeColumnEvents id={data?.meta?.anchor} {...data} />;
          case `announcement-w-image`:
            return <AnnouncementWImage id={data?.meta?.anchor} {...data} />;
          case `cap-overview`:
            return <CapOverview id={data?.meta?.anchor} {...data} />;
          case `cta-options`:
            return <CtaOptions id={data?.meta?.anchor} {...data} />;
          case `stats-grid`:
            return <StatsGrid id={data?.meta?.anchor} {...data} />;
          case `announcement-short`:
            return <AnnouncementShort id={data?.meta?.anchor} {...data} />;
          case `important-dates`:
            return <ImportantDates id={data?.meta?.anchor} {...data} />;
          case `accordion-rich-text`:
            return <AccordionRichText id={data?.meta?.anchor} {...data} />;
          case `accordion-cards`:
            return <AccordionCards id={data?.meta?.anchor} {...data} />;
          case `intro-w-images`:
            return (
              <IntroWImages id={data?.meta?.anchor} {...data} family={family} />
            );
          case `intro-w-links`:
            return (
              <IntroWLinks id={data?.meta?.anchor} {...data} family={family} />
            );
          case `intro-two-columns`:
            return (
              <IntroTwoColumns
                id={data?.meta?.anchor}
                {...data}
                family={family}
              />
            );
          case `hero-simple`:
            return <HeroSimple id={data?.meta?.anchor} {...data} />;
          case `card-carousel`:
            return <CardCarousel id={data?.meta?.anchor} {...data} />;
          case `cards-w-cta`:
            return <CardsWCta id={data?.meta?.anchor} {...data} />;
          case `card-grid`:
            return <CardGrid id={data?.meta?.anchor} {...data} />;
          case `detailed-link-list`:
            return <DetailedLinkList id={data?.meta?.anchor} {...data} />;
          case `heading-w-images`:
            return <HeadingWImages id={data?.meta?.anchor} {...data} />;
          case `heading-w-stats`:
            return <HeadingWStats id={data?.meta?.anchor} {...data} />;
          case `testimonial`:
            return <Testimonial id={data?.meta?.anchor} {...data} />;
          case `important-announcements`:
            return <ImportantAnnouncements id={data?.meta?.anchor} {...data} />;
          case `link-list-columns`:
            return <LinkListColumns id={data?.meta?.anchor} {...data} />;
          case `quick-links`:
            return <QuickLinks id={data?.meta?.anchor} {...data} />;
          case `end-of-page-cta`:
            return <EndOfPageCta id={data?.meta?.anchor} {...data} />;
          case `hero-home`:
            return <HeroHome id={data?.meta?.anchor} {...data} />;
          case `video-w-heading`:
            return <VideoWHeading id={data?.meta?.anchor} {...data} />;
          case `relative-nav`:
            return (
              <RelativeNav id={data?.meta?.anchor} {...data} family={family} />
            );
          case `table`:
            return (
              <TableBlock id={data?.meta?.anchor} {...data} family={family} />
            );
          case `button`:
            return (
              <ButtonBlock id={data?.meta?.anchor} {...data} family={family} />
            );
          case `code`:
            return (
              <CodeBlock id={data?.meta?.anchor} {...data} family={family} />
            );
          default:
            return null;
        }
      }
      return null;
    });
  }
  return null;
};

ResolveBlocks.defaultProps = {
  blocks: null,
  family: null,
};

export default ResolveBlocks;
