import React from "react";
import clsx from "clsx";
import { Button, Text, RichText } from "@atoms";

const ContentWButton = ({
  heading,
  subHeading,
  button,
  variant,
  singleCol,
  copy,
}) => {
  let headingSize;
  switch (variant) {
    case "lg":
      headingSize = "h3";
      break;
    default:
      headingSize = "h5";
      break;
  }
  return (
    <div
      className={`flex ${
        singleCol ? "flex-col" : "flex-row flex-wrap items-end"
      } -mx-3 md:flex-nowrap`}
    >
      <div className="mr-auto w-full max-w-lg flex-grow px-3">
        {heading && (
          <Text variant="mono--large-label" className="text-blue-dark">
            {heading}
          </Text>
        )}
        {subHeading && (
          <Text
            variant={headingSize}
            className={clsx("font-bold leading-tight text-black", {
              "my-6": singleCol,
            })}
          >
            {subHeading}
          </Text>
        )}
        {copy && (
          <Text
            variant="body"
            className={`text-black ${
              singleCol ? "my-6" : "mt-2"
            } leading-normal`}
          >
            <RichText content={copy} />
          </Text>
        )}
      </div>
      {button && button?.label?.length > 1 && (
        <div className="mt-4 flex-shrink-0 px-3">
          <Button color="blue" to={button.link}>
            {button.label}
          </Button>
        </div>
      )}
    </div>
  );
};

ContentWButton.defaultProps = {
  heading: null,
  subHeading: null,
  button: null,
  variant: "md",
  singleCol: false,
  copy: null,
};

export default ContentWButton;
