import React, { useState, useRef, useEffect } from "react";
import { search as Eyeglass } from "@svg";
import { navigate } from "gatsby";

// todo: use framer motion

const SearchBar = ({
  id,
  inverse,
  canSearch,
  searchFor,
  onQueryChange,
  initialQuery,
  focusOnLoad,
  placeholder,
}) => {
  const [term, setTerm] = useState(initialQuery);
  const searchContainer = useRef();
  const search = useRef();

  useEffect(() => {
    setTerm(initialQuery);
  }, [initialQuery]);

  useEffect(() => {
    if (focusOnLoad) {
      search.current.focus();
    }
  }, []);

  const handleKeyPress = e => {
    if (e.key === "Enter") {
      if (canSearch) {
        if (searchFor) {
          searchFor(term);
        } else {
          navigate(`/search/?q=${term}`);
        }
      }
    }
  };

  const handleSubmit = () => {
    if (canSearch) {
      if (searchFor) {
        searchFor(term);
      } else {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        navigate(`/search/?q=${term}`);
      }
    }
  };

  useEffect(() => {
    if (onQueryChange) {
      onQueryChange(term);
    }
  }, [term]);

  return (
    <div
      ref={searchContainer}
      className={`relative flex h-10 items-center justify-start border-b-2 ${
        inverse ? "border-blue-superlight" : "border-blue-dark"
      }`}
    >
      {/* input */}
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor={id || "search"} className="sr-only">
        search
      </label>
      <input
        id={id || "search"}
        name={id || "search"}
        ref={search}
        placeholder={placeholder || "Search"}
        value={term}
        onChange={() => setTerm(search.current.value)}
        onInput={() => setTerm(search.current.value)}
        onKeyPress={handleKeyPress}
        className={`w-0 bg-transparent ${
          inverse
            ? "text-blue-superlight placeholder-blue-extralight"
            : "text-blue-dark placeholder-blue"
        } placeholder-mono flex-grow font-mono text-base transition`}
      />

      {/* search button */}
      <button
        type="button"
        disabled={!canSearch}
        className={`relative z-20 transition ${inverse ? "text-blue-superlight" : "text-blue"}`}
        onClick={handleSubmit}
      >
        <span className="sr-only">close</span>
        <div className="icon h-6 w-6 fill-current">
          <Eyeglass />
        </div>
      </button>
    </div>
  );
};

SearchBar.defaultProps = {
  inverse: true,
  canSearch: true,
  searchFor: null,
  onQueryChange: null,
  initialQuery: "",
  focusOnLoad: false,
};

export default SearchBar;
