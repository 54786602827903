import React from "react";

import { Accordion } from "@molecules";
import { Text, Icon, RichText } from "@atoms";
import Container from "../atoms/Container";

// all accordion components need open prop
const Head = ({ heading, number, open, id }) => {
  return (
    <div id={id} className="div flex items-center border-t border-peach p-2">
      <div className="flex-shink-0 w-auto p-2">
        <Icon
          name="dropdown"
          className={`h-8 w-8 transform text-blue-light ${
            open ? "rotate-0" : "-rotate-90"
          } transition`}
        />
      </div>
      {number && (
        <div className="w-auto flex-shrink-0 p-2">
          {/* period here is not a typo */}
          <Text variant="body--large" className="font-bold text-blue">
            {`${number}.`}
          </Text>
        </div>
      )}
      <div>
        <Text variant="body--large" className="text-left font-bold text-blue">
          {heading}
        </Text>
      </div>
    </div>
  );
};

// all accordion components need open prop
// eslint-disable-next-line no-unused-vars
const Content = ({ richText, open }) => {
  return (
    <div className="flex p-4 pb-6 pt-0">
      <div className="flex-shink-0 w-auto p-6" />
      <Text variant="body">
        <RichText content={richText} />
      </Text>
    </div>
  );
};

const AccordionRichText = ({
  id,
  heading,
  subHeading,
  sections: sectionsData,
  numbering: _numbering,
}) => {
  // convert string
  const numbering =
    typeof _numbering === "boolean" ? _numbering : _numbering === "enabled";
  // map populated components to sections array
  const sections = sectionsData
    ? sectionsData.map((section, i) => {
        return {
          head: Head,
          headData: {
            heading: section.heading,
            number: numbering ? i + 1 : false,
          },
          content: Content,
          contentData: { richText: section.richText },
        };
      })
    : false;
  // now the actual component
  return (
    <div id={id} className="cabrillo-block py-16">
      <div className="px-0 sm:px-4 lg:px-12 xl:px-8">
        <div className="mx-auto w-full max-w-5xl px-6 sm:px-8 md:px-10 lg:px-16 xl:max-w-6xl xl:px-24">
          {heading && (
            <Text variant="h3" className="mb-2 w-full text-blue">
              {heading}
            </Text>
          )}
          {subHeading && (
            <Text
              variant="h4"
              className="my-2 w-full leading-tight text-blue-dark"
            >
              {subHeading}
            </Text>
          )}
        </div>
      </div>
      <Container padding>
        {sections && <Accordion sections={sections} />}
      </Container>
    </div>
  );
};

AccordionRichText.defaultProps = {
  heading: null,
  subHeading: null,
  sections: null,
  numbering: true,
};

Content.defaultProps = {
  richText: null,
  open: false,
};

Head.defaultProps = {
  heading: null,
  number: 0,
  open: false,
};

export default AccordionRichText;
