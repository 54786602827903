import React from "react";

import { Icon } from "@atoms";
import { uid } from "react-uid";
import { removeNonNumeric } from "@utils";

const LocationsList = ({ locations }) => {
  return (
    <div className="-mx-3 flex flex-wrap sm:-mx-6 md:flex-nowrap">
      {locations &&
        locations.map(loc => {
          return (
            <div
              key={uid(loc)}
              className="mb-6 flex w-full px-3 sm:mb-0 sm:w-auto lg:px-6"
            >
              <Icon className="w-6 text-yellow" name="marker" />
              <div className="pl-1 leading-loose sm:text-xs">
                <div className="text-white">{loc.name}</div>
                <div className="whitespace-pre-wrap text-blue-extralight">
                  <a
                    href={loc.googleMapsURL}
                    target="_blank"
                    className="my-3 w-auto text-white hover:text-orange"
                    rel="noreferrer"
                  >
                    {loc.address1}
                    <br />
                    {loc.cityStateZip}
                  </a>
                  <br />
                  <a
                    href={`tel:+1${removeNonNumeric(loc.phone)}`}
                    className="my-3 w-auto text-white hover:text-orange"
                  >
                    {loc.phone}
                  </a>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

LocationsList.defaultProps = {
  locations: null,
};

export default LocationsList;
