import React from "react";
import { Link } from "gatsby";

const AppLink = ({
  to: url,
  title,
  className: _className,
  children,
  onClick,
  activeClassName,
}) => {
  if (url && typeof url === "string") {
    const wpUrl = process.env.GATSBY_WORDPRESS_URL;
    const siteUrl = process.env.GATSBY_SITE_URL;
    // handle external links
    // usually open in new tab, noreferer, noopener
    if (
      url.charAt(0) === "#" ||
      url.includes("tel:") ||
      url.includes("mailto:")
    ) {
      return (
        <a
          href={url || ""}
          title={title}
          name={title}
          className={`${_className || ""} cursor-pointer`}
        >
          {children}
        </a>
      );
    }
    if (
      url.includes(wpUrl) ||
      url.includes(siteUrl) ||
      (!url.includes("https://") && !url.includes("http://"))
    ) {
      const to = url.replace(wpUrl, "").replace(siteUrl, "").replace("//", "/");
      return (
        <Link
          className={`${_className} cursor-pointer`}
          activeStyle={{ cursor: "default" }}
          activeClassName={activeClassName}
          partiallyActive
          title={title}
          name={title}
          to={to}
          onClick={onClick}
          exit={{
            length: 0.35,
          }}
          entry={{
            delay: 0.35,
            length: 0.2,
          }}
        >
          {children}
        </Link>
      );
    }
    // handle internal links
    // usually internal router and/or transition
    return (
      <a
        href={url || ""}
        title={title}
        name={title}
        target="_blank"
        rel="noopener noreferrer"
        className={`${_className || ""} cursor-pointer`}
      >
        {children}
      </a>
    );
  }
  return <span className={`${_className || ""}`}>{children}</span>;
};

AppLink.defaultProps = {
  to: null,
  title: null,
  className: null,
  onClick: null,
  activeClassName: null,
};

export default AppLink;
