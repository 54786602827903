import React from "react";

import { AppLink } from "@defaults";
import { Text, Icon } from "@atoms";

const DetailedLink = ({
  link,
  label,
  icon,
  desc,
  linkObj: LinkObj,
  inverse,
}) => {
  if (link) {
    return (
      <LinkObj
        to={link.link}
        className={`flex flex-col items-start ${inverse ? "text-white" : "text-blue-dark"} group transition hover:text-orange`}
        title={label}
      >
        <div className="flex flex-row items-center">
          <div
            className={`mr-3 flex-shrink-0 text-blue ${inverse ? "text-blue-light" : "text-blue"}`}
          >
            <Icon name={icon || "page"} className="h-8 w-8" />
          </div>
          <Text variant="mono--small-label" className="underline-1">
            {link.label}
          </Text>
        </div>
        {desc && (
          <div className="mt-3 flex flex-row pl-3">
            <div className="ml-8 flex-grow">
              <Text
                variant="body--small"
                className={` ${inverse ? "text-white" : "text-black"} leading-tight`}
              >
                {desc}
              </Text>
            </div>
          </div>
        )}
      </LinkObj>
    );
  }
  return null;
};

DetailedLink.defaultProps = {
  link: null,
  label: null,
  icon: null,
  desc: null,
  linkObj: AppLink,
  inverse: false,
};

export default DetailedLink;
