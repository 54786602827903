import React from "react";

import { Text, Container, Image, Button, RichText, Lines } from "@atoms";
import { Carousel } from "@molecules";

const ContentWCarousel = ({
  heading,
  subHeading,
  copy,
  button,
  images,
  background,
  decoration,
  id,
}) => {
  let headingColor = "white";
  let subHeadingColor = "blue-dark";
  let textColor = "blue-dark";
  let linesColor = "blue";
  switch (background) {
    case "white":
      headingColor = "blue";
      subHeadingColor = "blue-dark";
      textColor = "black";
      linesColor = "blue";
      break;
    // case "blue-light":
    //   headingColor = "blue";
    //   subHeadingColor = "blue-dark";
    //   textColor = "black";
    //   highlightColor = "white";
    //   break;
    // case "blue-dark":
    //   headingColor = "blue-extralight";
    //   subHeadingColor = "white";
    //   textColor = "white";
    //   highlightColor = "blue-light";
    //   break;
    case "blue-extralight":
      headingColor = "blue";
      subHeadingColor = "blue-dark";
      textColor = "black";
      linesColor = "white";
      break;
    // case "peach":
    //   headingColor = "blue";
    //   subHeadingColor = "blue-dark";
    //   textColor = "black";
    //   highlightColor = "orange";
    //   break;
    // case "yellow":
    //   headingColor = "blue";
    //   subHeadingColor = "blue-dark";
    //   textColor = "black";
    //   highlightColor = "blue";
    //   break;
    default:
      break;
  }
  return (
    <div
      id={id}
      className={`cabrillo-block bg-${background} relative py-12 sm:py-16 md:py-20`}
    >
      {decoration === "lines" && (
        <div
          className={`absolute inset-0 z-0 text-${linesColor} w-full md:w-2/3`}
        >
          <Lines position="object-top-left" />
        </div>
      )}
      <Container className="relative z-10">
        {/* heading + subHeading */}
        {heading && heading.length > 1 && (
          <Text
            variant="mono--small-label"
            className={`text-${headingColor} mb-3`}
          >
            {heading}
          </Text>
        )}
        {subHeading && subHeading.length > 1 && (
          <Text
            variant="h4"
            className={`font-bold text-${subHeadingColor} mb-3`}
          >
            {subHeading}
          </Text>
        )}
        {/* copy + button */}
        <div className="-mx-3 flex flex-wrap items-center justify-between md:flex-nowrap">
          {copy && (!!copy.length || typeof copy === "object") && (
            <Text
              variant="body"
              className={`rich-text mb-3 px-3 text-${textColor}`}
            >
              <RichText content={copy} />
            </Text>
          )}
          {button && (
            <div className="mt-6 w-auto flex-shrink-0 px-3">
              <Button to={button.link} size="sm" color="orange">
                {button.label}
              </Button>
            </div>
          )}
        </div>
        <div className="-mx-3 mb-4 mt-8 sm:-mx-6 md:mb-10">
          <Carousel showMultiple maxVisible={1} centerItems>
            {images &&
              images.length &&
              images.map((slide, i) => {
                const { image, caption } = slide;
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={i} className="px-3 sm:px-6">
                    <Image {...image} caption={caption} />
                  </div>
                );
              })}
          </Carousel>
        </div>
      </Container>
    </div>
  );
};

ContentWCarousel.defaultProps = {
  heading: null,
  subHeading: null,
  copy: null,
  button: null,
  image: null,
  links: null,
  background: "blue-light",
  images: null,
  decoration: null,
};

export default ContentWCarousel;
