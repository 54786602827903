import React from "react";

import { Image, RichText, Text, Container } from "@atoms";

const HeadingWImageAndCopy = ({ image, heading, copy, id }) => {
  return (
    <div id={id} className="py-12 sm:py-12">
      <Container>
        <div className="mb-6">
          <Text variant="h3" className="font-bold text-blue-dark">
            {heading}
          </Text>
        </div>
        <div className="-mx-2 flex flex-wrap">
          <div className="w-full px-2 sm:w-2/5">
            <Image {...image} />
          </div>
          <div className="w-full px-2 sm:w-3/5">
            <Text variant="body">
              <RichText content={copy} />
            </Text>
          </div>
        </div>
      </Container>
    </div>
  );
};

HeadingWImageAndCopy.defaultProps = {
  image: null,
  heading: null,
  copy: null,
};

export default HeadingWImageAndCopy;
