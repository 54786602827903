import React from "react";

import { Text } from "@atoms";

const SideBarHours = ({ days, hours }) => {
  return (
    <div>
      <Text variant="mono--extra-small" className="mb-1 font-bold text-blue">
        {days}
      </Text>
      <Text variant="xs" className="font-bold text-blue-dark">
        {hours}
      </Text>
    </div>
  );
};

SideBarHours.defaultProps = {
  days: null,
  hours: null,
};

export default SideBarHours;
