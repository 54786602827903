import React from "react";
import { Card, Cta } from "@molecules";
import { Text, Lines, Container } from "@atoms";

const CardsWCta = ({ heading, cards, cta, id }) => {
  // get either from first item in array or directly-handed object
  const ctaSettings = cta[0] || cta;
  return (
    <div id={id} className="cabrillo-block relative bg-blue py-12 sm:py-24">
      <Container padding>
        <div className="absolute inset-0 z-0 w-full text-white md:w-2/3">
          <Lines position="object-top-left" />
        </div>
        <div className="relative z-10">
          <Text variant="h3" className="mb-16 text-center font-bold text-white">
            {heading}
          </Text>
          <div className="-mx-3 flex flex-wrap">
            {!!cards?.length &&
              cards.map((card, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={i} className="mb-6 w-full px-3 md:w-1/3">
                  <Card {...card} inverse ar="8:5" />
                </div>
              ))}
          </div>
          <div>
            <Cta {...ctaSettings} background="blue-dark" />
          </div>
        </div>
      </Container>
    </div>
  );
};

CardsWCta.defaultProps = {
  heading: null,
  cards: null,
  cta: [{ ...Cta.defaults }],
};

export default CardsWCta;
