import React from "react";

import { Container } from "@atoms";
import { Card, ContentWButton } from "@molecules";

const ThreeColumnCards = ({ heading, subHeading, button, cards, id }) => {
  return (
    <div id={id} className="relative py-12 sm:py-16">
      <Container className="relative z-10" padding>
        {heading && (
          <div className="mb-12">
            <ContentWButton
              heading={heading}
              subHeading={subHeading}
              button={button}
            />
          </div>
        )}
        <div className="-mx-3 flex flex-wrap">
          {!!cards?.length &&
            cards.map((card, i) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <div key={i} className="mb-6 w-full px-3 sm:w-1/2 md:w-1/3">
                  <Card {...card} size="sm" ar="8:5" />
                </div>
              );
            })}
        </div>
      </Container>
    </div>
  );
};

ThreeColumnCards.defaultProps = {
  heading: null,
  subHeading: null,
  button: null,
  cards: null,
};

export default ThreeColumnCards;
