export const decode64 = string => {
  // eslint-disable-next-line no-useless-escape
  const base64Rejex =
    // eslint-disable-next-line no-useless-escape
    /^(?:[A-Z0-9+\/]{4})*(?:[A-Z0-9+\/]{2}==|[A-Z0-9+\/]{3}=|[A-Z0-9+\/]{4})$/i;
  const isBase64Valid = base64Rejex.test(string); // base64Data is the base64 string

  if (isBase64Valid) {
    const encodeB64 =
      typeof window !== "undefined"
        ? atob(string)
        : Buffer.from(string, "base64").toString("ascii");
    return encodeB64;
  }
  return string;
};

export default decode64;
