import React from "react";

import { Container } from "@atoms";
import { LinkList } from "@molecules";

const LinkListColumns = ({ columns, id }) => {
  return (
    <div id={id} className="bg-blue-extralight py-12">
      <Container padding>
        <div className="-mx-3 flex flex-wrap sm:-mx-6 md:-mx-12">
          {columns &&
            columns.length > 0 &&
            columns.map((col, i) => {
              const hasBorder = i !== columns.length - 1 && i % 2 === 0;
              return (
                <div
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  className={`w-full px-3 py-6 pr-12 sm:px-6 sm:pr-24 md:w-1/2 md:px-12 ${
                    hasBorder ? "border-blue md:border-r-2" : ""
                  }`}
                >
                  <LinkList {...col} />
                </div>
              );
            })}
        </div>
      </Container>
    </div>
  );
};

LinkListColumns.defaultProps = { columns: [] };

export default LinkListColumns;
