import React from "react";

import { Helmet } from "react-helmet";
import parse from "html-react-parser";

const Meta = ({ html }) => {
  if (html) {
    return <Helmet>{parse(html)}</Helmet>;
  }
  return null;
};

export default Meta;
