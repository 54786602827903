import { useStaticQuery, graphql } from "gatsby";
import moment from "moment";

const query = graphql`
  query CalendarQuery {
    allCalendarEvent {
      edges {
        node {
          id: ActivityId
          building: BuildingCode
          # not used
          # description: Description
          endDate: EndDate
          endMinute: EndMinute
          roomName: RoomName
          roomNumber: RoomNumber
          startDate: StartDate
          startMinute: StartMinute
          title: ActivityName
          category
          featured
        }
      }
    }
  }
`;

const dataResolver = ({ allCalendarEvent }) => {
  const categories = [];
  const events = allCalendarEvent.edges.map(node => {
    const {
      id,
      building,
      endDate,
      endMinute,
      roomName,
      roomNumber,
      startDate,
      startMinute,
      title,
      category,
    } = node.node;
    const startTime = `${String(Math.floor(startMinute / 60)).padStart(
      2,
      "0"
    )}:${String(endMinute % 60).padStart(2, "0")}`;
    const endTime = `${String(Math.floor(endMinute / 60)).padStart(
      2,
      "0"
    )}:${String(startMinute % 60).padStart(2, "0")}`;
    const start = moment(`${startDate.split("T")[0]} ${startTime}`).format();
    const end = moment(`${endDate.split("T")[0]} ${endTime}`).format();
    if (!categories.includes(category)) {
      categories.push(category);
    }
    return {
      id,
      title,
      start,
      end,
      category,
      building,
      roomName,
      roomNumber,
    };
  });
  return {
    events,
    categories,
  };
};

const useData = () => {
  const response = useStaticQuery(query);
  return dataResolver(response);
};

export default useData;
