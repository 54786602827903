import React, { useState, useRef, useEffect } from "react";

import useWindowSize from "@hooks/useWindowSize";
import { Container, Text, RichText } from "@atoms";
import { AppLink } from "@defaults";
import SideBar from "./SideBar";

const IntroWLinks = ({
  heading,
  sideBarImage,
  sideBarSections,
  lead,
  copy,
  links,
  linkObj: LinkObj,
  family,
  id,
}) => {
  const [minHeight, setMinHeight] = useState("none");
  const sidebarContainer = useRef();
  const { innerWidth: windowSize } = useWindowSize();
  useEffect(() => {
    setTimeout(() => {
      if (sidebarContainer.current) {
        setMinHeight(sidebarContainer.current.clientHeight - 48);
      }
    }, 500);
  }, [windowSize]);
  return (
    <Container>
      <div
        id={id}
        style={{ minHeight }}
        className="relative pt-6 sm:pt-12 lg:py-12"
      >
        <Container
          padding={sideBarSections || sideBarImage}
          interior={sideBarSections || sideBarImage}
        >
          <div
            className={`${
              sideBarSections || sideBarImage ? "lg:pl-32 xl:pl-32" : ""
            } pt-2`}
          >
            <div className="max-w-2xl">
              {heading && (
                <Text
                  variant="h4"
                  className="mb-12 font-bold text-blue-dark lg:mt-12"
                >
                  {heading}
                </Text>
              )}
              {lead && (
                <div>
                  <Text
                    variant="body--large"
                    className="rich-text mb-6 leading-normal"
                  >
                    <RichText content={lead} />
                  </Text>
                </div>
              )}
              {copy && (
                <div>
                  <Text variant="body" className="rich-text leading-normal">
                    <RichText content={copy} />
                  </Text>
                </div>
              )}
              <div className="two-columns -mx-3 mt-6">
                {links &&
                  links.length > 0 &&
                  links.map((item, i) => {
                    const { link } = item;
                    if (link) {
                      return (
                        // eslint-disable-next-line react/no-array-index-key
                        <div key={i} className="mb-6 w-full px-3">
                          <LinkObj
                            to={link.link}
                            className="font-bold text-blue transition hover:text-orange"
                          >
                            <Text variant="body--large">{link.label}</Text>
                          </LinkObj>
                        </div>
                      );
                    }
                    return null;
                  })}
              </div>
            </div>
            {/* sidebar */}
            {(sideBarSections || sideBarImage) && (
              <div className="-mx-3 sm:-mx-6">
                <div
                  ref={sidebarContainer}
                  className="z-20 order-2 my-6 w-full lg:absolute lg:left-0 lg:top-0 lg:order-1 lg:-mb-20 lg:-ml-12 lg:-mt-6 lg:w-auto"
                >
                  <div className="lg:-ml-3">
                    <SideBar
                      image={sideBarImage}
                      sections={sideBarSections}
                      family={family}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </Container>
      </div>
    </Container>
  );
};

IntroWLinks.defaultProps = {
  sideBarImage: null,
  sideBarSections: null,
  lead: null,
  copy: null,
  links: null,
  linkObj: AppLink,
  heading: null,
};

export default IntroWLinks;
