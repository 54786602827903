const initialState = {
  modal: false,
  content: false,
  background: "bg-[#000]",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "openModal":
      return {
        ...state,
        modal: true,
        content: action.content,
        background: action.background || initialState.background,
      };
    case "closeModal":
      return {
        ...state,
        modal: false,
      };
    case "clearModalContent":
      return {
        ...state,
        content: false,
        background: initialState.background,
      };
    default:
      return state;
  }
};

const layout = {
  reducer,
  initialState,
};

export default layout;
