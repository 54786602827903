import React from "react";
import { Icon } from "@atoms";

const Pagination = ({ pages, currentPage, setPage }) => {
  // construct array of page #s
  const pageArray = [...Array(pages)].map((_, i) => i + 1);
  return (
    <div className="flex items-center justify-center">
      {/* previous */}
      <button
        type="button"
        disabled={currentPage <= 1}
        className={` ${currentPage <= 1 ? "opacity-0" : ""} mx-1 flex h-5 w-5 rotate-180 transform items-center justify-center rounded-full bg-blue-extralight p-px text-blue-dark transition hover:bg-orange`}
        onClick={() => {
          setPage(currentPage - 1);
        }}
      >
        <span className="sr-only">previous</span>
        <Icon name="arrow" className="h-4 w-4" />
      </button>
      {/* pages */}
      {currentPage - 3 > 1 && (
        <button
          type="button"
          disabled={currentPage === 1}
          className={` ${currentPage === 1 ? "bg-blue-light" : "hover:bg-orange"} mx-1 flex h-6 w-6 items-center justify-center rounded-full text-blue-dark transition`}
          onClick={() => {
            setPage(1);
          }}
        >
          1
        </button>
      )}
      {currentPage - 3 > 1 && <span className="mx-1 block">...</span>}
      {pageArray
        .slice(currentPage - 3 > 1 ? currentPage - 3 : 0, currentPage + 2)
        .map(page => {
          return (
            <button
              key={page}
              type="button"
              disabled={currentPage === page}
              className={` ${currentPage === page ? "bg-blue-light" : "hover:bg-orange"} mx-1 flex h-6 w-6 items-center justify-center rounded-full text-blue-dark transition`}
              onClick={() => {
                setPage(page);
              }}
            >
              {page}
            </button>
          );
        })}
      {currentPage + 3 < pageArray.length && (
        <span className="mx-1 block">...</span>
      )}
      {currentPage + 3 < pageArray.length && (
        <button
          type="button"
          disabled={currentPage === pageArray.length}
          className={` ${
            currentPage === pageArray.length
              ? "bg-blue-light"
              : "hover:bg-orange"
          } mx-1 flex h-6 w-6 items-center justify-center rounded-full text-blue-dark transition`}
          onClick={() => {
            setPage(pageArray.length);
          }}
        >
          {pageArray.length}
        </button>
      )}
      {/* next */}
      <button
        type="button"
        disabled={currentPage >= pages}
        className={` ${currentPage >= pages ? "opacity-0" : ""} mx-1 flex h-5 w-5 items-center justify-center rounded-full bg-blue-extralight p-px text-blue-dark transition hover:bg-orange`}
        onClick={() => {
          setPage(currentPage + 1);
        }}
      >
        <span className="sr-only">next</span>
        <Icon name="arrow" className="h-4 w-4" />
      </button>
    </div>
  );
};

Pagination.defaultProps = {};

export default Pagination;
