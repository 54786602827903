import React from "react";

import { AppLink } from "@defaults";
import { Text } from "@atoms";

const FamilyNav = ({ family }) => {
  if (family) {
    const {
      siblings: _siblings,
      children: _children,
      parent,
      currentPage,
    } = family;
    const siblings = _siblings?.sort((a, b) => a.order - b.order);
    const children = _children?.sort((a, b) => a.order - b.order);
    return (
      <ul className="text-xs">
        {parent.path && (
          <li className="mb-3 font-bold text-blue-dark">
            <AppLink to={parent.path}>
              <Text variant="sm">{parent.title}</Text>
            </AppLink>
          </li>
        )}
        {siblings &&
          siblings.length > 0 &&
          siblings.map((s, i) => {
            if (s.title !== currentPage.title) {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <li key={i} className="mb-3 font-bold text-blue">
                  <AppLink to={s.path}>
                    <Text variant="xs">{s.title}</Text>
                  </AppLink>
                </li>
              );
            }
            return (
              // eslint-disable-next-line react/no-array-index-key
              <li key={i} className="mb-3 font-bold text-black">
                <div className="mb-3">
                  <Text variant="xs">{s.title}</Text>
                </div>
                <ul>
                  {children &&
                    children.length > 0 &&
                    children.map((c, ii) => {
                      return (
                        // eslint-disable-next-line react/no-array-index-key
                        <li key={ii} className="mb-3 text-blue">
                          <AppLink to={c.path}>
                            <Text variant="xs">{c.title}</Text>
                          </AppLink>
                        </li>
                      );
                    })}
                </ul>
              </li>
            );
          })}
      </ul>
    );
  }
  if (!process.env.GATSBY_SITE_URL) {
    return (
      <div>
        <Text
          variant="mono--extra-small-label"
          className="text-center text-orange"
        >
          Relative Nav not available during preview.
        </Text>
      </div>
    );
  }
  return null;
};

FamilyNav.defaultProps = {
  family: null,
};

export default FamilyNav;
