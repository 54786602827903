import React from "react";

import { Text, Image, Button, Container, RichText } from "@atoms";

const AnnouncementWImage = ({
  heading,
  subHeading,
  background,
  copy,
  button,
  image,
  layout,
  id,
}) => {
  let headingColor;
  let subHeadingColor;
  let textColor;
  let buttonColor;
  switch (background) {
    case "blue-extralight":
      headingColor = "text-blue-dark";
      subHeadingColor = "text-black";
      textColor = "text-black";
      buttonColor = "blue";
      break;
    case "blue":
      headingColor = "text-blue-extralight";
      subHeadingColor = "text-white";
      textColor = "text-white";
      buttonColor = "yellow";
      break;
    case "blue-dark":
      headingColor = "text-blue-light";
      subHeadingColor = "text-white";
      textColor = "text-white";
      buttonColor = "orange";
      break;
    default:
      headingColor = "text-blue-medium";
      subHeadingColor = "text-blue-dark";
      textColor = "text-black";
      buttonColor = "blue";
      break;
  }
  return (
    <div id={id} className={`cabrillo-block bg-${background}`}>
      <Container padding>
        <div className="-mx-3 flex flex-wrap items-center py-12 sm:py-16">
          <div
            className={`relative mb-6 w-full px-3 md:mb-0 md:w-2/5 ${
              layout !== "right" ? "md:order-2" : ""
            }`}
          >
            <Image {...image} aspectratio="4:3" />
          </div>
          <div
            className={`w-full px-3 md:w-3/5 ${
              layout === "right"
                ? "md:order-1 md:pl-6 lg:pl-12"
                : "md:pr-6 lg:pr-12"
            }`}
          >
            {heading && heading.length > 1 && (
              <Text
                variant="mono--small-label"
                className={`mb-3 font-bold ${headingColor}`}
              >
                {heading}
              </Text>
            )}
            {subHeading && subHeading.length > 1 && (
              <Text
                variant="h6"
                className={`mb-3 font-bold leading-tight ${subHeadingColor}`}
              >
                {subHeading}
              </Text>
            )}
            {copy && (!!copy.length || typeof copy === "object") && (
              <Text
                variant="body--small"
                className={`mb-3 leading-normal ${textColor}`}
              >
                <RichText content={copy} />
              </Text>
            )}
            {button && (
              <div className="-mx-1 mt-6 flex flex-wrap">
                <div className="mb-3 w-auto px-2">
                  <Button to={button.link} size="sm" color={buttonColor}>
                    {button.label}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

AnnouncementWImage.defaultProps = {
  heading: null,
  subHeading: null,
  copy: null,
  button: null,
  image: null,
  layout: "left",
  background: "peach",
};

export default AnnouncementWImage;
