import React from "react";

import { Text, Container } from "@atoms";
import { CardLocation } from "@molecules";

const CardsLocations = ({ heading, subHeading, cards, id }) => {
  return (
    <div id={id} className="py-12 sm:py-16">
      <Container>
        <div className="cabrillo-block mx-auto mr-auto w-full max-w-xl px-3 leading-none">
          {heading && (
            <Text
              variant="mono--large-label"
              className="mb-1 w-full text-center text-blue"
            >
              {heading}
            </Text>
          )}
          {subHeading && (
            <Text
              variant="h4"
              className="mb-12 w-full text-center font-bold leading-tight text-blue-dark"
            >
              {subHeading}
            </Text>
          )}
        </div>
        <div className="-mx-3 flex flex-wrap">
          {!!cards?.length &&
            cards.map((card, i) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <div key={i} className="mb-6 w-full px-3 md:w-1/2">
                  <CardLocation {...card} />
                </div>
              );
            })}
        </div>
      </Container>
    </div>
  );
};

CardsLocations.defaultProps = { heading: null, subHeading: null, cards: null };

export default CardsLocations;
