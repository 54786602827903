import React from "react";
import clsx from "clsx";
import { AppLink } from "@defaults";

/* accessibility options
* blue-dark background

orange
outline white

* blue background

yellow
white-outline

* peach / blue-extralight / blue-superlight

blue
outline dark blue

*/

const Button = ({
  color = "orange",
  outline = false,
  to,
  title,
  type = "button",
  onClick,
  linkObj: LinkObj = AppLink,
  children,
  size = "md",
  className: _className,
}) => {
  const buttonClasses = [];
  if (outline) {
    buttonClasses.push("bg-transparent");
    switch (color) {
      case "white":
        buttonClasses.push(
          "border-white text-white hover:bg-blue-light hover:border-blue-light hover:text-black"
        );
        break;
      default:
        buttonClasses.push(
          "border-blue-dark text-blue-dark hover:bg-blue-dark hover:text-white"
        );
    }
  } else {
    switch (color) {
      case "yellow":
        buttonClasses.push(
          "bg-yellow border-yellow text-black hover:bg-blue-light hover:border-blue-light"
        );
        break;
      case "blue":
        buttonClasses.push(
          "bg-blue border-blue text-white hover:bg-blue-dark hover:border-blue-dark"
        );
        break;
      default: // orange
        buttonClasses.push(
          "bg-orange border-orange text-black hover:bg-blue-light hover:border-blue-light"
        );
    }
  }

  const classes = clsx(
    buttonClasses,
    "inline-flex cursor-pointer border-2 font-mono items-center rounded-full transition duration-200",
    _className,
    {
      "py-1 px-5 text-xxs": size === "xs",
      "py-2 px-6 text-xs": size === "sm",
      "py-2 px-6 text-sm": size !== "sm" && size !== "xs",
    }
  );

  if (to && to.length > 0) {
    return (
      <LinkObj to={to} title={title} className={`${classes}`}>
        {children}
      </LinkObj>
    );
  }
  return (
    // eslint-disable-next-line react/button-has-type
    <button type={type} className={`${classes}`} onClick={onClick}>
      {children}
    </button>
  );
};

export default Button;
