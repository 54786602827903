import React from "react";

import { Text, RichText } from "@atoms";

const EventListItemWhite = ({ date, heading, copy }) => {
  return (
    <>
      <div className="-mx-3 flex flex-wrap py-6 md:flex-nowrap">
        <div className="w-full flex-shrink-0 md:w-56">
          <Text
            variant="mono--small-label"
            className="mb-3 mt-1 px-3 text-blue"
          >
            {date}
          </Text>
        </div>
        <div className="w-full px-3 text-blue-dark md:w-auto">
          <Text variant="body" className="font-bold">
            {heading}
          </Text>
          <Text variant="body">
            <RichText content={copy} />
          </Text>
        </div>
      </div>
      <div className="h-px w-full bg-pink opacity-50" />
    </>
  );
};

EventListItemWhite.defaultProps = { date: null, heading: null, copy: null };

export default EventListItemWhite;
