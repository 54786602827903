/**
 * Implement Gatsby's SSR (Server Side Rendering) APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/ssr-apis/
 */

/* eslint-disable global-require */

// import styles index
import "./src/css/index.css";
import { AnimatePresence, MotionConfig } from "framer-motion";

import React from "react";
import { AppStateProvider } from "@state/state";
import Default from "@layout/DefaultLayout";

export const wrapPageElement = ({ element, props }) => {
  const { pageContext } = props;
  return (
    <Default pageContext={pageContext}>
      <MotionConfig transition={{ duration: 0.3, type: "tween" }}>
        <AnimatePresence mode="wait" initial={false}>
          {element}
        </AnimatePresence>
      </MotionConfig>
    </Default>
  );
};

export const wrapRootElement = ({ element }) => {
  return <AppStateProvider>{element}</AppStateProvider>;
};
