import React from "react";

import { Card } from "@molecules";
import { Text, Container, RichText } from "@atoms";

const CardGrid = ({
  heading,
  subHeading,
  cards,
  headerAlignment,
  alignment,
  background,
  copy,
  id,
}) => {
  return (
    <div id={id} className={`cabrillo-block bg-${background}`}>
      <Container padding>
        <div className="py-16">
          <div className={`text-${alignment || headerAlignment} mb-10`}>
            {heading && (
              <Text variant="mono--large-label" className="text-blue-medium">
                {heading}
              </Text>
            )}
            {subHeading && (
              <Text
                variant="h4"
                className="font-bold leading-tight text-blue-dark"
              >
                {subHeading}
              </Text>
            )}
            {copy && (
              <Text
                variant="h4"
                className="font-bold leading-tight text-blue-dark"
              >
                <RichText content={copy} />
              </Text>
            )}
          </div>
          <div className="-mx-3 flex flex-wrap">
            {!!cards?.length &&
              cards.map((card, i) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={i} className="mb-6 w-full px-3 sm:w-1/2">
                    <Card {...card} ar="8:5" copyColor="black" />
                  </div>
                );
              })}
          </div>
        </div>
      </Container>
    </div>
  );
};

CardGrid.defaultProps = {
  heading: null,
  subHeading: null,
  cards: null,
  alignment: null,
  headerAlignment: "center",
  background: "white",
  copy: null,
};

export default CardGrid;
