import React from "react";

import { Icon, Text, Button, Lines, Container } from "@atoms";

const Cta = ({
  heading,
  subHeading,
  primaryButton,
  secondaryButton,
  background,
  decoration,
  small,
}) => {
  let headingColor;
  let subHeadingColor;
  let iconColor;
  let primaryButtonColor;
  let secondaryButtonColor;
  switch (background) {
    case "blue-extralight":
      headingColor = "text-blue";
      subHeadingColor = "text-blue-dark";
      iconColor = "blue";
      primaryButtonColor = "blue";
      secondaryButtonColor = "blue-dark";
      break;
    case "blue":
      headingColor = "text-blue-extralight";
      subHeadingColor = "text-white";
      iconColor = "blue-light";
      primaryButtonColor = "yellow";
      secondaryButtonColor = "white";
      break;
    case "blue-dark":
      headingColor = "text-blue-light";
      subHeadingColor = "text-white";
      iconColor = "blue";
      primaryButtonColor = "orange";
      secondaryButtonColor = "white";
      break;
    default:
      headingColor = "text-blue";
      subHeadingColor = "text-blue-dark";
      iconColor = "white";
      primaryButtonColor = "blue";
      secondaryButtonColor = "blue-dark";
      break;
  }
  return (
    <div className="relative z-10 -mb-12 sm:-mb-20">
      <Container
        padding
        interior
        className={`bg-${background} ${small ? "py-12 md:py-16" : "py-12 md:py-16 lg:py-20"} relative`}
      >
        <div className="relative z-10 w-full md:w-2/3 lg:w-3/5">
          <Text
            variant="mono--large-label"
            className={`mb-3 font-bold ${headingColor}`}
          >
            {heading}
          </Text>
          {/* if subheading */}
          {subHeading && (
            <Text
              variant="h5"
              className={`mb-8 font-bold leading-tight ${subHeadingColor}`}
            >
              {subHeading}
            </Text>
          )}
          <div className="-mx-2 flex flex-wrap">
            {/* if primaryButton */}
            {primaryButton && primaryButton.link && primaryButton?.label && (
              <div className="mt-3 px-2">
                <Button to={primaryButton.link} color={primaryButtonColor}>
                  {primaryButton?.label}
                </Button>
              </div>
            )}

            {/* if secondaryButton */}
            {secondaryButton &&
              secondaryButton.link &&
              secondaryButton.label && (
                <div className="mt-3 px-2">
                  <Button
                    to={secondaryButton.link}
                    color={secondaryButtonColor}
                    outline
                  >
                    {secondaryButton.label}
                  </Button>
                </div>
              )}
          </div>
        </div>
        {decoration === "lines" && (
          <div className="pointer-events-none absolute bottom-0 right-0 w-4/5 text-blue">
            <Lines fewer />
          </div>
        )}
        {decoration === "crest" && (
          <div className="pointer-events-none absolute bottom-0 right-0 top-0 z-0 flex items-center p-3 sm:p-12 md:p-16 lg:p-20">
            <Icon
              name="crest"
              className={`w-48 opacity-30 text-${iconColor}`}
            />
          </div>
        )}
      </Container>
    </div>
  );
};

Cta.defaultProps = {
  heading: "Your Pathway to Success",
  subHeading: null,
  primaryButton: null,
  secondaryButton: null,
  background: "peach",
  decoration: "crest",
  small: false,
};

export default Cta;
