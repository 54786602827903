import React from "react";
import { Text, Icon } from "@atoms";
import { AppLink } from "@defaults";
import { titleCase } from "title-case";

// eslint-disable-next-line no-unused-vars
const SearchResult = ({ title, path, desc, image, LinkObj, count }) => {
  let breadcrumbs = [];
  const wpUrl = process.env.GATSBY_WORDPRESS_URL;
  const siteUrl = process.env.GATSBY_SITE_URL;
  if (path) {
    const split = path.replace(wpUrl, "").replace(siteUrl, "").split("/");
    breadcrumbs = split.slice(1, breadcrumbs.length - 1);
    // breadcrumbs.splice(breadcrumbs.length - 1,1 );
  }
  // eslint-disable-next-line no-useless-escape
  const reString = `${process.env.GATSBY_IMGIX_DOMAIN}(?!\/)`;
  const regex = new RegExp(reString, "g");

  // need to iterate this way to ignore empty crumbs
  // eslint-disable-next-line no-unused-vars
  let idx = 0;
  return (
    <div
      key={`${String(count)}-${path}`}
      className="animate-fade-in border-b border-blue-extralight py-6 sm:py-10"
    >
      <div className="flex flex-wrap sm:flex-nowrap">
        <div className="mb-3 w-full pr-3 sm:mb-0 sm:w-auto">
          <LinkObj to={path} className="mb-2 block text-blue hover:text-orange">
            <Text variant="h6" className="font-bold">
              {title}
            </Text>
          </LinkObj>
          {breadcrumbs && breadcrumbs.length > 0 && (
            <div className="flex items-center">
              {breadcrumbs.map((crumb, i) => {
                const crumbpath = `/${breadcrumbs.slice(0, i + 1).join("/")}`;
                if (
                  breadcrumbs.length < 4 ||
                  i > 2 ||
                  i < breadcrumbs.length - 2
                ) {
                  idx += 1;
                  return (
                    <LinkObj
                      // eslint-disable-next-line react/no-array-index-key
                      key={i}
                      to={crumbpath}
                      className="hover:blue-orange inline-flex items-center text-xs font-bold text-blue-light"
                    >
                      {i > 1 && (
                        <Icon
                          name="arrow"
                          className="mx-2 -mb-px h-3 w-3 text-blue-dark"
                        />
                      )}
                      {titleCase(crumb.replace("-", " ").replace("-", " "))}
                    </LinkObj>
                  );
                }
                return null;
              })}
            </div>
          )}
          {desc && (
            <Text variant="body--small" className="mt-4">
              {`${desc}...`}
            </Text>
          )}
        </div>
        {image && (
          <div className="relative h-40 w-40 flex-shrink-0 sm:ml-auto sm:h-auto sm:w-1/3 md:w-1/4">
            <img
              src={image.replace(regex, `${process.env.GATSBY_IMGIX_DOMAIN}/`)}
              alt={title}
              className="h-full w-full object-cover"
            />
          </div>
        )}
      </div>
    </div>
  );
};

SearchResult.defaultProps = {
  title: null,
  path: null,
  desc: null,
  image: null,
  LinkObj: AppLink,
  count: 0,
};

export default SearchResult;
