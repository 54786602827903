/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useRef } from "react";
import { m } from "framer-motion";

const AccordionSection = ({
  head: Head,
  headData,
  content: Content,
  contentData,
  number,
}) => {
  if (Head && Content) {
    const [open, setOpen] = useState(number === 0);
    const contentContainer = useRef();

    return (
      <div className="relative overflow-hidden">
        <div>
          <button
            type="button"
            onClick={() => {
              setOpen(!open);
            }}
            className="block w-full cursor-pointer"
          >
            <Head {...headData} open={open} />
          </button>
        </div>
        <m.div
          animate={
            !open
              ? { height: 0, overflow: "hidden" }
              : {
                  height: "auto",
                  duration: 0.25,
                  ease: "power1.inOut",
                  overflow: "visible",
                }
          }
          ref={contentContainer}
        >
          <Content {...contentData} open={open} />
        </m.div>
      </div>
    );
  }
  return null;
};

AccordionSection.defaultProps = {
  head: null,
  content: null,
  number: 0,
  headData: null,
  contentData: null,
};

export default AccordionSection;
