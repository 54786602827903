import React, { useState } from "react";

import { Button } from "@atoms";
import { AppLink } from "@defaults";
import { m } from "framer-motion";
import MegaMenu from "./MegaMenu";

const PrimaryNav = ({ links, button, linkObj: LinkObj }) => {
  const [megaNavOpen, setMegaNavOpen] = useState(false);
  const [hovered, setHovered] = useState(false);

  const openNav = i => {
    if (megaNavOpen !== i + 1 && hovered !== i + 1) setMegaNavOpen(i + 1);
    setHovered(i + 1);
  };

  const closeNav = i => {
    if (megaNavOpen === i + 1) setMegaNavOpen(false);
    setHovered(false);
  };

  return (
    <div className="-mx-3 flex items-center justify-end">
      {links &&
        links.map((link, i) => {
          return (
            <m.div
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              className="px-3 text-sm font-bold text-white transition hover:text-blue-light lg:text-base"
              onFocus={() => openNav(i)}
              onBlur={() => closeNav(i)}
              onHoverStart={() => openNav(i)}
              onHoverEnd={() => closeNav(i)}
            >
              {/* handle mouseover */}
              <LinkObj
                to={link.link}
                onClick={() => setMegaNavOpen(false)}
                className="inline-block py-6 lg:text-base"
              >
                {link.label}
              </LinkObj>
              {link.links && link.links.length > 0 && (
                <div
                  className="absolute left-0 w-full overflow-hidden bg-white"
                  style={{ top: "100%" }}
                >
                  <MegaMenu
                    links={link.links}
                    linkObj={LinkObj}
                    open={megaNavOpen === i + 1}
                    setOpen={setMegaNavOpen}
                  />
                </div>
              )}
            </m.div>
          );
        })}
      {button && (
        <div className="px-3">
          <Button to={button.link} size="sm" color="orange">
            {button.label}
          </Button>
        </div>
      )}
    </div>
  );
};

PrimaryNav.defaultProps = {
  linkObj: AppLink,
  links: null,
  button: null,
};

export default PrimaryNav;
