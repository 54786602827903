import React from "react";

import { Container, Text, Image } from "@atoms";
import { Carousel } from "@molecules";

const component = ({ heading, subHeading, images, id }) => {
  if (images && images.length > 0) {
    return (
      <div id={id} className="relative overflow-hidden py-12">
        <Container variant="xs" padding>
          <div className="relative z-10">
            <Text variant="mono--large-label" className="text-blue">
              {heading}
            </Text>
            <Text variant="h5" className="font-bold text-blue-dark">
              {subHeading}
            </Text>
            <div className="-mx-3 mb-4 mt-8 sm:-mx-6 md:mb-10">
              <Carousel showMultiple maxVisible={1} centerItems>
                {images.map((slide, i) => {
                  const { image, caption } = slide;
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={i} className="px-3 sm:px-6">
                      <Image {...image} caption={caption} />
                    </div>
                  );
                })}
              </Carousel>
            </div>
          </div>
        </Container>
      </div>
    );
  }
  return null;
};

component.defaultProps = {
  heading: null,
  subHeading: null,
  images: null,
  button: null,
};

export default component;
