import React from "react";

import { Text, Container, RichText } from "@atoms";

const Copy = ({ heading, subHeading, size, copy, layout, id }) => {
  const sizes = {
    large: "body--large",
    normal: "body",
    small: "body--small",
  };
  const variant = sizes[size] || "body";
  return (
    <div id={id} className="copy-block py-12 sm:py-16">
      <Container variant={layout === "Two Columns" ? "md" : "xs"} padding>
        {(heading || subHeading) && (
          <div className="mb-6">
            {heading && (
              <Text variant="mono--large-label" className="text-blue">
                {heading}
              </Text>
            )}
            {subHeading && (
              <Text
                variant="h5"
                className="font-bold leading-tight text-blue-dark"
              >
                {subHeading}
              </Text>
            )}
          </div>
        )}
        <Text
          variant={variant}
          className={` ${layout === "Two Columns" ? "two-columns--copy" : ""} rich-text`}
        >
          <RichText content={copy} />
        </Text>
      </Container>
    </div>
  );
};

Copy.defaultProps = {
  size: "normal",
  heading: "",
  copy: null,
  subHeading: "",
  layout: "One Column",
};

export default Copy;
