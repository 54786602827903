import React from "react";

import { Container, Icon } from "@atoms";
import { Card, EventListSmall, ContentWButton } from "@molecules";
import useCalendarData from "@staticQueries/useCalendarData";

// todo: fix hooks

const ImportantAnnouncements = ({
  heading,
  subHeading,
  button,
  cards,
  eventsCategory,
  id,
}) => {
  const events = useCalendarData()?.events.filter(event =>
    eventsCategory === "featured"
      ? event.featured
      : event.category === eventsCategory
  );
  return (
    <div className="py-12" id={id}>
      <Container className="relative" padding>
        <div className="absolute inset-0 z-0 flex items-start justify-end p-6 lg:p-12">
          <Icon
            name="crest"
            className="w-1/4 max-w-md text-blue-light opacity-10 lg:w-full"
          />
        </div>
        <div className="relative z-10">
          {(heading || subHeading) && (
            <div className="mb-12">
              <ContentWButton
                heading={heading}
                subHeading={subHeading}
                button={button}
              />
            </div>
          )}
          <div className="-mx-3 flex flex-wrap sm:-mx-6">
            {/* return the first card large */}
            {cards && cards[0] && (
              <div className="mb-6 w-full px-3 sm:px-6 md:w-1/2 lg:w-3/5">
                <Card {...cards[0]} ar="5:4" />
              </div>
            )}
            {/* return the rest of the cards off to the right */}
            <div className="w-full px-3 sm:px-6 md:w-1/2 lg:w-2/5">
              {cards &&
                cards.length &&
                cards.map((card, i) => {
                  if (i > 0) {
                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <div key={i} className="mb-8">
                        <Card {...card} size="sm" ar="8:5" />
                      </div>
                    );
                  }
                  return null;
                })}
              {eventsCategory && events && <EventListSmall events={events} />}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

ImportantAnnouncements.defaultProps = {
  heading: null,
  subHeading: null,
  button: null,
  cards: null,
  eventsCategory: null,
};

export default ImportantAnnouncements;
